import React, { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { Form, Button, FormGroup, Input, Label } from "reactstrap";
import { useMutation, useQuery } from "@tanstack/react-query";
import { confirmAlert } from 'react-confirm-alert'; 
import InputErrors from "../../commons/components/InputErrors";
import AdService from "../services/AdService";
import { toast } from 'react-toastify';
import { handleErrors } from "../../commons/functions";


const types = [
    { id: 1, value: 'TYPE_WEB_IMAGE_SMALL', label: 'advert.type.small' },
]

const UploadAdForm = ( { campaignId, adId } ) => {

  const [selectedAdType, setSelectedAdvertType] = useState(null);
  const [advertTypes, setAdvertTypes] = useState([]);
  const [edit, setEdit] = useState(adId ? true : false);
  const [ad, setAd] = useState(false);
  const [imgSrc, setImageSrc] = useState(null);
  const intl = useIntl();
  const fileInputRef = useRef(null);

  const { register, handleSubmit, control, setError, setFocus, setValue, reset, formState: { errors } } = useForm({
    mode: "onSubmit",
  });

  useEffect(() => {
    if (ad) {
      setImageSrc(ad.src)
      setSelectedAdvertType(ad.type)
      setValue('type', ad.type)
      setValue('url', ad.clickUrl)
      setValue('premium', ad.premium)
      setValue('active', ad.active)
    }
  }, [ad, setValue]);

  useEffect(() => {
    setAdvertTypes(types)
    if (!edit) {
    setFocus("type");
    }
  }, [setFocus, edit]);

  const adToEdit = useQuery([adId], () => AdService.get(adId), {
    enabled: edit,
    onSuccess: (response) => setAd( response.data )
  });

  const updateMutation = useMutation(([data, setError]) => AdService.update(campaignId, adId, data), {
    onSuccess: (response, vars, context) => {
      toast.success(intl.formatMessage({id: "advert-upload.msg.edit.success"}));
      adToEdit.refetch();
      setEdit(true);
    },
    onError: (error, [data, setError], context) => {
      handleErrors(error, "advert-upload.msg.edit.error", setError, toast, intl);
    }
  });


  const deleteMutation = useMutation(([setError]) => AdService.delete(campaignId, adId), {
    onSuccess: (response, vars, context) => {
      toast.success(intl.formatMessage({id: "advert-upload.msg.delete.success"}));
      setAd(null);
      setSelectedAdvertType(null);
      setImageSrc(null);
      reset();
      setEdit(false);
    },
    onError: (error, [data, setError], context) => {
      handleErrors(error, "advert-upload.msg.delete.error", setError, toast, intl);
    }
  });
  

  const addMutation = useMutation(([data, setError]) => AdService.create(campaignId, data), {
    onSuccess: (response, vars, context) => {
      toast.success(intl.formatMessage({id: "advert-upload.msg.create.success"}));
      setAd(response.data);
      setEdit(true);
    },
    onError: (error, [data, setError], context) => {
      handleErrors(error, "advert-upload.msg.create.error", setError, toast, intl);
    }
  });

  const { ref: refType, ...registerType } = register('type', { required: true });
  const { ref: refUrl, ...registerUrl } = register('url');
  const { ref: refFile, ...registerFile } = register('file', { required: !edit });
  const { ref: refPremium, ...registerPremium } = register('premium');
  const { ref: refActive, ...registerActive } = register('active');

  const onSubmit = (data, setError) => {
    if (edit) {
      updateMutation.mutate([data, setError]);
    }
    else {
      addMutation.mutate([data, setError]);
    }
  };

  const deleteDialog = {
     title : intl.formatMessage({id:"advert-upload.action.delete.confirm"}),
     message : intl.formatMessage({ id:"advert-upload.action.delete.message"}),
     ok : intl.formatMessage({id: "buttons.confirm"}),
     cancel : intl.formatMessage({id: "buttons.cancel"}),
  }

  const handleDelete = (setError) => {
    confirmAlert({
      title:  deleteDialog.title,
      message:  deleteDialog.message,
      buttons: [
        {
          label: deleteDialog.ok,
          onClick: () => deleteMutation.mutate([setError])
        },
        {
          label: deleteDialog.cancel 
        }
      ]
    });
  }


  const handleTypeSelect = (event) => {
    setSelectedAdvertType(event.target.value)
  }

  const handleFileSelect = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedAdType === 'TYPE_WEB_IMAGE_SMALL') {
      if (!selectedFile.type.toLowerCase().startsWith('image')) {
        handleErrors({ type: "Unsupported File Type" }, "advert-upload.error.unsupported.type.small", setError, toast, intl)
      }
      else {
        setImageSrc(URL.createObjectURL(selectedFile))
      }
    }
    else {
      handleErrors({ type: "Unsupported Advert Type" }, "advert-upload.error.unsupported.type.generic", setError, toast, intl)
    }

  }

  return (
    <React.Fragment>
      <h2><FormattedMessage id="advert-upload.title"/></h2> 
      <Form id={campaignId} onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col">
            <FormGroup >
              <Label for='type'>
                <FormattedMessage id="advert-upload.fields.type" />
              </Label>
                <Input id='type' type='select' innerRef={refType} {...registerType}  onChange={val => handleTypeSelect(val)} >          
                  <option value=''><FormattedMessage id="advert-upload.prompt.type"/></option>
                  {advertTypes.map(t => <option key={t.id} value={t.value}><FormattedMessage id={t.label} /></option>)}
                </Input>
              <InputErrors fieldName="type" errors={errors} />
            </FormGroup>
            <FormGroup className="row">
              <div className="col">
                <Label for='file'>
                  <FormattedMessage id="advert-upload.fields.file" />
                </Label>
                <Input id='file' type="file" ref={fileInputRef} innerRef={refFile} {...registerFile} onChange={handleFileSelect} disabled={!selectedAdType} />
              </div>
              <InputErrors fieldName="file" errors={errors} />
            </FormGroup>
            <FormGroup>
              <Label for='url'>
                <FormattedMessage id="advert-upload.fields.url" />
              </Label>
              <Input id='url' type="url" innerRef={refUrl} {...registerUrl} disabled={!selectedAdType} />
              <InputErrors fieldName="url" errors={errors} />
            </FormGroup>
            <FormGroup switch>
              <Label for='isPremium'>
                <FormattedMessage id="advert-upload.fields.premium" />
              </Label>
              <Input id="isPremium"  name="isPremium" type="switch" role='switch' innerRef={refPremium} {...registerPremium} disabled={!selectedAdType}/>
              <InputErrors fieldName="isPremium" errors={errors} />
            </FormGroup>
            <FormGroup switch>
              <Label for='isActive'>
                <FormattedMessage id="advert-upload.fields.active" />
              </Label>
              <Input id="isActive"  name="isActive" type="switch" role='switch' innerRef={refActive} {...registerActive} disabled={!selectedAdType}/>
              <InputErrors fieldName="isActive" errors={errors} />
            </FormGroup>
            <FormGroup className={"d-flex justify-content-around px-5"}>
              <Button color="secondary" type="submit" >
                <FormattedMessage id={ edit ? "advert-upload.action.edit" : "advert-upload.action.create"} />
              </Button>
              { edit ?
                <Button color="danger" onClick={handleDelete} type="button" >
                  <FormattedMessage id="advert-upload.action.delete" />
              </Button> : null
              }
            </FormGroup>
          </div>
            { imgSrc ? 
              <div className="col">
                <img src={imgSrc} lazy="true" alt="adImage"/> 
              </div> 
            : null }
        </div>
      </Form>
    </React.Fragment>
  );
};

export default UploadAdForm;
