import React, { Fragment, useEffect, useState } from "react";
import CustomSpinner from "../../commons/components/Spinner";
import { FormattedMessage, useIntl } from "react-intl";
import Moment from "react-moment";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import DeliveryConfigService from "../services/DeliveryConfigService";
import ListPagination from "../../commons/components/ListPagination";
import DataTable from "../../commons/components/DataTable";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import WarningMessage from "../../commons/components/WarningMessage";
import DeliveryConfigForm from "./DeliveryConfigForm";
import { toast } from 'react-toastify';
import { handleApiErrors, handleErrors } from "../../commons/functions";

const dataTableColumns = [
  {
    headerKey: "delivery-config.fields.name",
    selector: row => row.name
  },
  {
    headerKey: "delivery-config.fields.startDate",
    selector: row => <Moment format='DD/MM/YYYY'>{row.startDate}</Moment>
  },
  {
    headerKey: "delivery-config.fields.endDate",
    selector: row => <Moment format='DD/MM/YYYY'>{row.endDate}</Moment>
  }
];

const DeliveryConfigList = ({onSelect, selected}) => {

  const pageSize = 10;

  const [currentPage, setCurrentPage] = useState(0);
  const [page, setPage] = useState({total: 0, list: []});
  const [selectedRow, setSelectedRow] = useState(null);
  const [openForm, setOpenForm] = useState(false);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [openTecInfo, setOpenTecInfo] = useState(false);

  const intl = useIntl();

  const result = useQuery(["delivery-config-list", currentPage, pageSize], () => DeliveryConfigService.list(currentPage, pageSize), {
    onSuccess: (response) => setPage({total: response.data.total, list: response.data.data })
  });

  const addMutation = useMutation(([data, setError]) => DeliveryConfigService.create(data), {
    onSuccess: (response, vars, context) => {
      toast.success(intl.formatMessage({id: "delivery-config.msg.create"}));
      result.refetch();
      toggleForm();
    },
    onError: (error, [data, setError], context) => {
      handleErrors(error, "delivery-config.msg.error.create", setError, toast, intl);
    }
  });

  const deleteMutation = useMutation((identifier) => DeliveryConfigService.delete(identifier), {
    onSuccess: (response, [identifier], context) => {
      toast.success(intl.formatMessage({id: "delivery-config.msg.delete"}));
      result.refetch();
      if (identifier === selected) {
        onSelect(null);
      }
    },
    onError: (error, vars, context) => toast.error(handleApiErrors(intl, error, "delivery-config.msg.error.delete")),
    onSettled: () => {
      toggleDelete();
    }
  });

  const updateMutation = useMutation(([identifier, data, setError]) => DeliveryConfigService.update(identifier, data), {
    onSuccess: (response, vars, context) => {
      toast.success(intl.formatMessage({id: "delivery-config.msg.update"}));
      result.refetch();
      toggleForm();
    },
    onError: (error, [identifier, data, setError], context) => {
      handleErrors(error, "delivery-config.msg.error.update", setError, toast, intl);
    }
  });

  useEffect(() => {
    if (!openForm) {
      setSelectedRow(null);
    }
  }, [openForm]);

  useEffect(() => {
    if (!openDeleteConfirm) {
      setSelectedRow(null);
    }
  }, [openDeleteConfirm]);

  const onPageChange = (e, pageTotLoad) => {
    e.preventDefault();

    if (result.isLoading) {
      return;
    }

    setCurrentPage(pageTotLoad);
  }

  const toggleForm = () => {
    setOpenForm(oldValue =>!oldValue);
  };

  const toggleDelete = () => {
    setOpenDeleteConfirm(oldValue =>!oldValue);
  };

  const toggleTecInfo = () => {
    setOpenTecInfo(oldValue => !oldValue);
  };

  const handleSave = (data, setError) => {
    if (selectedRow) {
      updateMutation.mutate([selectedRow.id, data, setError]);
    } else {
      addMutation.mutate([data, setError]);
    }
  };

  const handleDelete = () => {
    deleteMutation.mutate([selectedRow.id]);
  };

  const handleConfirmDelete = (row) => {
    setSelectedRow(row);
    toggleDelete();
  };

  const handleUpdate = (row) => {
    setSelectedRow(row);
    toggleForm();
  }

  const handleOnSelect = (row, rowIndex) => {
    if (selected === row.id) {
      onSelect(null);
    } else {
      onSelect(row.id);
    }
  }
  let content = null;

  if (result.isLoading) {
    content = (
      <CustomSpinner messageKey="delivery-config.loading" />
    );
  } else if (result.isError) {
    content = (
      <FormattedMessage id="delivery-config.loading.error" />
    );
  } else {

    const pagination = <ListPagination page={currentPage} totalItems={page.total} pageSize={pageSize} onPageChange={onPageChange} showSinglePage={false} />;

    content = (
      <Fragment>
        <DataTable responsive bordered hover className='delivery-config-table' data={page.list} columns={dataTableColumns} onSelect={handleOnSelect} selected={row => selected === row.id}
          actionsHeaderStyle={{width: "60px"}} renderRowActions={(row, rowIndex) => {
            
            if (rowIndex !== 0) {
              return (
                <i className="fas fa-info-circle me-2" role="button" onClick={toggleTecInfo}></i>
              );
            }

            return (
              <Fragment>
                <i className="fas fa-edit me-2" role="button" onClick={() => handleUpdate(row)}></i>
                <i className="fas fa-trash-alt" role="button" onClick={() => handleConfirmDelete(row)}></i>
              </Fragment>
            );
          }}/>
        {pagination}
      </Fragment>
    );

  }

  return (
    <div>
      <div className='d-flex justify-content-between'>
        <h2 className='section-title fs-5 flex-grow-1'><FormattedMessage id="delivery-config" /></h2>
        <Button className='ms-2' size='sm' disabled={result.isLoading || addMutation.isLoading || updateMutation.isLoading} onClick={toggleForm}>
          <i className='fas fa-plus me-0 me-md-2' />
          <span className='d-none d-md-inline'>
            <FormattedMessage id="delivery-config.buttons.add" />
          </span>
        </Button>
      </div>
      <div className='section-content'>
        {content}
      </div>
      {openDeleteConfirm ? <DeleteModal open={openDeleteConfirm} toggle={toggleDelete} onConfirm={handleDelete} disabled={deleteMutation.isLoading} /> : null}
      {openForm ? <ModalForm open={openForm} toggle={toggleForm} onSave={handleSave} data={selectedRow} disabled={addMutation.isLoading || updateMutation.isLoading}
        headerKey={selectedRow ? "delivery-config.edit": "delivery-config.add"} /> : null }
        {openTecInfo ? <TechnicalInfoModal open={openTecInfo} toggle={toggleTecInfo} /> : null }
    </div>
  );
};

export default DeliveryConfigList;

const DeleteModal = ({open, toggle, onConfirm, disabled = false}) => {

  return (
    <Modal isOpen={open} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>
        <FormattedMessage id="confirm" />
      </ModalHeader>
      <ModalBody>
        <WarningMessage messageKey="delivery-config.msg.delete.warning" />
        <FormattedMessage id="delivery-config.delete" />
      </ModalBody>
      <ModalFooter>
        <div className='d-flex justify-content-end'>
          <Button color='danger' onClick={onConfirm} disabled={disabled}>
            <FormattedMessage id="delivery-config.buttons.delete" />
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

const ModalForm = ({open, toggle, headerKey, data, disabled = false, onSave}) => {
  return (
    <Modal isOpen={open} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>
        <FormattedMessage id={headerKey} />
      </ModalHeader>
      <ModalBody>
        <DeliveryConfigForm id="delivery-config-form" onSave={onSave} defaultValues={data} disabled={disabled} />
      </ModalBody>
      <ModalFooter>
      <div className='d-flex justify-content-end'>
        <Button color='primary' disabled={disabled} type="submit" form="delivery-config-form">
          <FormattedMessage id="delivery-config.buttons.save" />
        </Button>
      </div>
      </ModalFooter>
    </Modal>
  );
};

const TechnicalInfoModal = ({open, toggle}) => {
  return (
    <Modal isOpen={open} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>
        <FormattedMessage id="info" />
      </ModalHeader>
      <ModalBody>
        <FormattedMessage id="delivery-config.msg.technicalContact" />
      </ModalBody>
    </Modal>
  );
}
