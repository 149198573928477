import api from "../../Api";
import { buildApiHeaders } from "../../commons/functions";

class ReportingService {

  static async list(page, pageSize, filter) {

    let auxFilter = {};
    if (filter) {
      auxFilter = filter;
    }

    auxFilter.page = page;
    auxFilter.pageSize = pageSize;

    const headers = buildApiHeaders();
    return api.post("/v1/admin/reports", auxFilter, {headers: headers});

  }

  static async delete(identifier) {
    const headers = buildApiHeaders();
    return api.delete(`/v1/admin/reports/${identifier}`, {headers: headers});
  }

  static async evaluationReport(reportData) {

    if (reportData?.mailCampaigns?.length > 0) {
      reportData.mailCampaigns = reportData.mailCampaigns.map(c => {return {id: c.id, name: c.title};})
    }

    const headers = buildApiHeaders();
    return api.post("/v1/admin/reports/evaluation-report", reportData, {headers: headers});

    //return new Promise(resolve => setTimeout(resolve, 5000));
  }

  static async generateDownloadUrl(reportId) {
    const headers = buildApiHeaders();
    return api.get(`/v1/admin/reports/${reportId}/downloadUrl`, {headers: headers});
  }

}

export default ReportingService;