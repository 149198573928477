
import { useLocalStorage } from './commons/hooks/useLocalStorage';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { LanguageContext } from './commons/context/LanguageContext';
import { IntlProvider } from 'react-intl';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { NoMatchPage } from './commons/containers/NoMatchPage';
import { AuthLayout } from './commons/components/AuthLayout';
import messages from './i18n/messages';
import DeliveryConfigurationPage from './deliveries/containers/DeliveryConfigurationPage';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import UserStatsPage from './users/containers/UserStatsPage';
import SharedDocumentListPage from './sharedDocuments/containers/SharedDocumentListPage';
import DeliveryFreePostalCodesPage from './deliveries/containers/DeliveryFreePostalCodesPage';
import AdPage from './ads/containers/AdPage';
import AlgoPage from './algo/containers/AlgoPage';
import ReportingPage from './reporting/containers/ReportingPage';
import EvaluationReportPage from './reporting/containers/EvaluationReportPage';

function App() {

  const search = window.location.search;
  const params = new URLSearchParams(search);
  
  const [language, setLanguage] = useLocalStorage("language", "en");
  const [auth, setAuth] = useLocalStorage("auth", null);

  // Create a client
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnmount: false,
        refetchOnReconnect: false
      }
    }
  });

  const router = createBrowserRouter([
    {
      path: "/",
      element: <AuthLayout />,
      children: [
        {
          path: "/deliveries/config",
          element: <DeliveryConfigurationPage />,
        },
        
        {
          path: "/deliveries/free-postal-codes",
          element: <DeliveryFreePostalCodesPage />,
        },
        {
          path: "/shared-documents",
          element: <SharedDocumentListPage />,
        },
        {
          path: "/users/:id/stats",
          element: <UserStatsPage />,
        },
        {
          path: "/campaign/:campaignId/ad/add",
          element: <AdPage />,
        },
        {
          path: "/campaign/:campaignId/ad/:adId/update",
          element: <AdPage />,
        },
        {
          path: "/campaign/:campaignId/ad/:adId/delete",
          element: <AdPage />,
        },
        {
          path: "/campaign/algorithm",
          element: <AlgoPage />,
        },
        {
          path: "/reporting",
          element: <ReportingPage />,
          children: [
            {
              path: "/reporting/evaluation",
              element: <EvaluationReportPage />,
              handle: {
                crumb: () => {return {title: "Evaluation report", path: "/reporting/evaluation"}}
              }
            }
          ],
          handle: {
            crumb: () => {return {title: "Reporting", path: "/reporting"}}
          }
        },
        {
          path: "*",
          element: <NoMatchPage />,
        },
      ]
    }
  ]);

   useEffect(() => {
    if (params.has("lang")) {
      setLanguage(params.get("lang"));
    }

    if (params.has("token")) {
      setAuth({token: params.get("token")});
     }
   }, []);

  if (auth === null) {
    return null;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <LanguageContext.Provider value={{language, setLanguage}}>
        <IntlProvider locale={language} messages={messages[language]}>
          <ToastContainer />
          <RouterProvider router={router} />
        </IntlProvider>
      </LanguageContext.Provider>
    </QueryClientProvider>
  );
}

export default App;
