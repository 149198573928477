import api from "../../Api";
import { buildApiHeaders } from "../../commons/functions";

class SharedDocumentService {

  static async list(page, pageSize) {
    const headers = buildApiHeaders();
    return api.post("/v1/shared-documents", {}, {params: {page: page, pageSize: pageSize}, headers: headers});
  }
  

  static async delete(documentId) {
    const headers = buildApiHeaders();
    return api.delete(`/v1/shared-documents/${documentId}`, {headers: headers});
  }

}

export default SharedDocumentService;