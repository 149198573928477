import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import UserService from "../services/UserService";
import CustomSpinner from "../../commons/components/Spinner";
import { FormattedMessage, useIntl } from "react-intl";

const UserStats = ({userId}) => {

  const [stats, setStats] = useState();
  const intl = useIntl();

  const result = useQuery(["user-stats", userId], () => UserService.getStats(userId), {
    enabled: userId !== undefined,
    onSuccess: (response) => setStats(response.data)
  });

  if (result.isLoading) {
    return (
      <CustomSpinner messageKey="users.stats.loading" />
    );
  }

  return (
    <div>
      <StatsItem intl={intl} labelKey="users.stats.uploads" value={stats?.UPLOADS} />
      <StatsItem intl={intl} labelKey="users.stats.prints" value={stats?.PRINTS} />
      <StatsItem intl={intl} labelKey="users.stats.pages" value={stats?.PAGES_PRINTED} />
      <div className="fw-bold"><FormattedMessage id="users.stats.friends" /></div>
      <StatsItem intl={intl} labelKey="users.stats.friends.registered" value={stats?.FRIENDS_ACTIVATED} boldLabel={false} />
      <StatsItem intl={intl} labelKey="users.stats.friends.printing" value={stats?.FRIENDS_WITH_PRINTS} boldLabel={false} />
    </div>
  );
};

export default UserStats;

const StatsItem = ({intl, labelKey, value, boldLabel = true}) => {

  return (
    <div className="d-flex align-items-center flew-wrap">
      <span className={boldLabel ? "fw-bold": null} style={{width: "300px"}}><FormattedMessage id={labelKey} defaultMessage={labelKey} />:</span>
      <span>{value ? intl.formatNumber(value) : 0}</span>
    </div>
  );
}