import React, { Fragment, useEffect, useMemo, useState } from "react";
import CustomSpinner from "../../commons/components/Spinner";
import { FormattedMessage, useIntl } from "react-intl";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import DataTable from "../../commons/components/DataTable";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import DeliveryConfigService from "../services/DeliveryConfigService";
import WarningMessage from "../../commons/components/WarningMessage";
import DeliveryConfigRangeForm from "./DeliveryConfigRangeForm";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { handleApiErrors, handleErrors } from "../../commons/functions";

const dataTableColumns = [
  {
    headerKey: "delivery-config.range.fields.min",
    headerClassName: "text-end",
    className: "text-end",
    selector: row => row.minimum
  },
  {
    headerKey: "delivery-config.range.fields.max",
    headerClassName: "text-end",
    className: "text-end",
    selector: row => row.maximum
  },
  {
    headerKey: "delivery-config.range.fields.price",
    headerClassName: "text-end",
    className: "text-end",
    selector: row => row.price
  },
  {
    headerKey: "delivery-config.range.fields.days",
    headerClassName: "text-end",
    className: "text-end",
    selector: row => row.days
  }
];


const DeliveryConfigRangeList = ({configId}) => {

  const intl = useIntl();

  const [list, setList] = useState([]);
  const [openForm, setOpenForm] = useState(false);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const noConfigSelected = useMemo(() => configId === null || configId === undefined, [configId]);

  const result = useQuery(["delivery-config-ranges", configId], () => DeliveryConfigService.ranges(configId), {
    enabled: !noConfigSelected,
    onSuccess: (response) => setList(response.data)
  });

  const addMutation = useMutation(([configId, data, setError]) => DeliveryConfigService.createRange(configId, data), {
    onSuccess: (response, vars, context) => {
      toast.success(intl.formatMessage({id: "delivery-config.range.msg.create"}));
      result.refetch();
      toggleForm();
    },
    onError: (error, [configId, data, setError], context) => {
      handleErrors(error, "delivery-config.range.msg.error.create", setError, toast, intl);
    }
  });

  const deleteMutation = useMutation(([configId, rangeId]) => DeliveryConfigService.deleteRange(configId, rangeId), {
    onSuccess: (response, vars, context) => {
      toast.success(intl.formatMessage({id: "delivery-config.range.msg.delete"}));
      result.refetch();
    },
    onError: (error, vars, context) => toast.error(handleApiErrors(intl, error, "delivery-config.range.msg.error.delete")),
    onSettled: (data, error, vars, context) => toggleDelete()
  });

  const updateMutation = useMutation(([configId, rangeId, data, setError]) => DeliveryConfigService.updateRange(configId, rangeId, data), {
    onSuccess: (response, vars, context) => {
      toast.success(intl.formatMessage({id: "delivery-config.range.msg.update"}));
      result.refetch();
      toggleForm();
    },
    onError: (error, [configId, rangeId, data, setError], context) => {
      handleErrors(error, "delivery-config.range.msg.error.update", setError, toast, intl);
    }
  });

  const handleDelete = () => {
    deleteMutation.mutate([configId, selectedRow.id]);
  };

  const handleConfirmDelete = (row) => {
    setSelectedRow(row);
    toggleDelete();
  };

  const handleUpdate = (row) => {
    setSelectedRow(row);
    toggleForm();
  }

  const handleSave = (data, setError) => {
    if (selectedRow) {
      updateMutation.mutate([configId, selectedRow.id, data, setError]);
    } else {
      addMutation.mutate([configId, data, setError]);
    }
  };

  useEffect(() => {
    if (!openForm) {
      setSelectedRow(null);
    }
  }, [openForm]);

  useEffect(() => {
    if (!openDeleteConfirm) {
      setSelectedRow(null);
    }
  }, [openDeleteConfirm]);

  const toggleForm = () => {
    setOpenForm(!openForm);
  };

  const toggleDelete = () => {
    setOpenDeleteConfirm(oldValue => !oldValue);
  };
  
  let content = null;
  if (noConfigSelected) {
    content = (
      <FormattedMessage id="delivery-config.range.msg.selectConfig" />
    );
  } else if (result.isLoading) {
    content = (
      <CustomSpinner messageKey="delivery-config.range.loading" />
    );
  } else if (result.isError) {
    content = (
      <FormattedMessage id="delivery-config.range.loading.error" />
    );
  } else if (list?.length === 0) {
    content = (
      <FormattedMessage id="delivery-config.range.msg.empty" />
    );
  } else {

    content = (
        <DataTable responsive bordered className='delivery-config-range-table' data={list} columns={dataTableColumns} actionsHeaderStyle={{width: "60px"}}
          renderRowActions={(row, rowIndex) => {
            return (
              <Fragment>
                <i className="fas fa-edit me-2" role="button" onClick={() => handleUpdate(row)}></i>
                <i className="fas fa-trash-alt" role="button" onClick={() => handleConfirmDelete(row)}></i>
              </Fragment>
            );
          }}/>
    );
  }

  return (
    <div>
      <div className='d-flex justify-content-between'>
        <h2 className='section-title fs-5 flex-grow-1'><FormattedMessage id="delivery-config.range" /></h2>
        <Button className='ms-2' size='sm' disabled={configId === null || result.isLoading || addMutation.isLoading || updateMutation.isLoading} onClick={toggleForm}>
          <i className='fas fa-plus me-0 me-md-2' />
          <span className='d-none d-md-inline'>
            <FormattedMessage id="delivery-config.range.buttons.add" />
          </span>
        </Button>
      </div>
      <div className='section-content'>
        {content}
      </div>
      {openDeleteConfirm ? <DeleteModal open={openDeleteConfirm} toggle={toggleDelete} onConfirm={handleDelete} disabled={deleteMutation.isLoading} /> : null}
      {openForm ? <ModalForm open={openForm} toggle={toggleForm} onSave={handleSave} data={selectedRow} disabled={addMutation.isLoading || updateMutation.isLoading}
        headerKey={selectedRow ? "delivery-config.range.edit": "delivery-config.range.add"} /> : null }
    </div>
  );
};

export default DeliveryConfigRangeList;

const DeleteModal = ({open, toggle, onConfirm, disabled = false}) => {

  return (
    <Modal isOpen={open} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>
        <FormattedMessage id="confirm" />
      </ModalHeader>
      <ModalBody>
        <FormattedMessage id="delivery-config.range.delete" />
      </ModalBody>
      <ModalFooter>
        <div className='d-flex justify-content-end'>
          <Button color='danger' onClick={onConfirm} disabled={disabled}>
            <FormattedMessage id="delivery-config.range.buttons.delete" />
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

const ModalForm = ({open, toggle, headerKey, data, disabled = false, onSave}) => {
  return (
    <Modal isOpen={open} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>
        <FormattedMessage id={headerKey} />
      </ModalHeader>
      <ModalBody>
        <DeliveryConfigRangeForm id="delivery-config-range-form" onSave={onSave} defaultValues={data} disabled={disabled} />
      </ModalBody>
      <ModalFooter>
      <div className='d-flex justify-content-end'>
        <Button color='primary' disabled={disabled} type="submit" form="delivery-config-range-form">
          <FormattedMessage id="delivery-config.range.buttons.save" />
        </Button>
      </div>
      </ModalFooter>
    </Modal>
  );
};