import React from "react";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Col, Form, FormGroup, Input, InputGroup, Label, Row } from "reactstrap";

const types = [
  "EVALUATION_REPORT"
];

const statuses = [
  "PENDING",
  "DONE",
  "ERROR"
];

const ReportsFilterForm = ({id, onFilter, current = {}, disabled = false}) => {

  const intl = useIntl();

  const { register, handleSubmit, setError, getValues, formState: { errors } } = useForm({
    mode: "onSubmit",
    defaultValues: current
  });

  const onSubmit = (data) => {

    // FIXME Validate start and end date

    onFilter(data, setError);
  };

  const handleOnSelectChange = (e) => {
    console.log(e);
    onSubmit(getValues());
  }

  const { ref: refName, ...registerName } = register('name', { "disabled": disabled });
  const { ref: refType, ...registerType } = register('type', { "disabled": disabled, onChange: handleOnSelectChange });
  const { ref: refStatus, ...registerStatus } = register('status', { "disabled": disabled, onChange: handleOnSelectChange });
  const { ref: refStartDate, ...registerStartDate } = register('startDate', { "disabled": disabled });
  const { ref: refEndDate, ...registerEndDate } = register('endDate', { "disabled": disabled });

  return (
    <Form id={id} onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col sm={12} md={3}>
          <FormGroup inline={true}>
            <Label for='status'>
              <FormattedMessage id="reports.fields.status" />
            </Label>
            <Input id="status" name="status" innerRef={refStatus} {...registerStatus} type="select">
              <option value=""></option>
              {statuses.map(item => <option key={item} value={item}>{item}</option>)}
            </Input>
          </FormGroup>
        </Col>
        <Col sm={12} md={3}>
          <FormGroup inline>
            <Label for='type'>
              <FormattedMessage id="reports.fields.type" />
            </Label>
            <Input id="type" name="type" innerRef={refType} {...registerType} type="select">
              <option value=""></option>
              {types.map(item => <option key={item} value={item}>{item}</option>)}
            </Input>
          </FormGroup>
        </Col>
        <Col sm={12} md={3}>
          <FormGroup>
            <Label for='name'>
              <FormattedMessage id="reports.fields.name" />
            </Label>
            <InputGroup>
              <Input id="name" name="name" type="search" innerRef={refName} {...registerName} />
              <Button color="secondary" disabled={disabled} title={intl.formatMessage({id: "reports.buttons.filter"})}>
                <i className="fas fa-search"></i>
              </Button>
            </InputGroup>
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
}

export default ReportsFilterForm;