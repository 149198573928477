export const resolvePath = (object, path, defaultValue) => path
   .split('.')
   .reduce((o, p) => o ? o[p] : defaultValue, object);

export function handleApiErrors(intl, apiError, defaultMessage) {

  if (apiError.response && apiError.response.data && apiError.response.data.errorCode) {

    if (apiError.response.data.errorParams) {

      const paramsAsObject = apiError.response.data.errorParams.map((p, index) => {
        var r = {"index": index.toString(), value: p};
        return r;
      }).reduce((previous, current) => {
        previous[current.index] = current.value;
        return previous;
      }, {});

      return intl.formatMessage({id: "errors.api.code." + apiError.response.data.errorCode, values: paramsAsObject})
    } else {
      return intl.formatMessage({id: "errors.api.code." + apiError.response.data.errorCode})
    }
  }

  return intl.formatMessage({id: defaultMessage});

}

export const handleErrors = (error, defaultErrorKey, setError, toast, intl) => {
  if (error.response && error.response.data && error.response.data.errors) {
    const errors = error.response.data.errors;

    Object.keys(errors).forEach(key => {
      console.log(key, errors[key]);
      setError(
        key,
        {
            "type": "manual",
            "message": errors[key]
        }
      );
    });
  } else {
    toast.error(handleApiErrors(intl, error, defaultErrorKey));
  }
}

export function buildApiHeaders() {
  const headers = {};
  if (localStorage.getItem("auth") != null) {
    const auth = JSON.parse(localStorage.getItem("auth"));
    if (auth && auth.token) {
      headers['X-AUTH-TOKEN'] = auth.token;
    }
  }

  let lang = "en";
  if (localStorage.getItem("language") != null) {
    const language = JSON.parse(localStorage.getItem("language"));
    if (language) {
      lang = language
    }
  }

  headers['Accept-Language'] = lang;

  return headers;
}

export const stringToColor = (str) => {
  let hash = 0;
  str.split('').forEach(char => {
    hash = char.charCodeAt(0) + ((hash << 5) - hash)
  })
  let colour = '#'
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff
    colour += value.toString(16).padStart(2, '0')
  }
  return colour
}

export const openDatePickerOnClick = (e) => {

  if (e?.target?.showPicker) {
    e.target.showPicker();
  }

}
