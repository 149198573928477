import React from "react"
import { Col, Container, Row } from "reactstrap";
import { Page } from "../../commons/components/Page";
import UserStats from "../components/UserStats";
import { useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";

const UserStatsPage = (props) => {

  const { id } = useParams();

  return (
    <Page>
      <Container fluid>
        <Row>
          <Col>
            <h2><FormattedMessage id="users.stats" /></h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <UserStats userId={id} />
          </Col>
        </Row>
      </Container>
    </Page>
  );
};

export default UserStatsPage;