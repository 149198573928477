import React from "react"
import { Col, Container, Row } from "reactstrap";
import { Page } from "../../commons/components/Page";
import DeliveryFreePostalCodeList from "../components/DeliveryFreePostalCodeList";

const DeliveryFreePostalCodesPage = (props) => {


  return (
    <Page>
      <Container fluid>
        <Row>
          <Col>
            <DeliveryFreePostalCodeList />
          </Col>
        </Row>
      </Container>
    </Page>
  );
};

export default DeliveryFreePostalCodesPage;