import React, { useState } from 'react'
import { Tooltip } from 'reactstrap'

const ToolTipItem = ({id, content, placement}) => {

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
      <Tooltip
        isOpen={tooltipOpen}
        target={id}
        toggle={toggle}
        placement={placement ?  placement : "right"} 
      >
        {content}
      </Tooltip>
  )
}

export default ToolTipItem
