import React, { useCallback, useState, useEffect } from "react";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import '@inovua/reactdatagrid-community/index.css'
import '@inovua/reactdatagrid-community/theme/default-dark.css';
import '@inovua/reactdatagrid-community/theme/blue-light.css';
import ToolTipItem from "../../commons/components/shared/ToolTipItem";


const HeaderDiv = ({data, content}) => {
  const id = data.replaceAll(" ", "").replace(/\./g, '')
  return (
    <>
          <div id={id} style={{height: '100%', width: '100%'}} >{data}</div>
          <ToolTipItem id={id} content={content} placement={"top"}/> 
    </>
  )
}

const CampaignList = ({campaigns, updateCampaigns, filtered, selected, setSelected}) => {
  const [data, setData] = useState([]);


  const columns = [
    { name: 'name', header: 'Campaign', editable: false, defaultFlex: 3, 
      renderHeader: (children) => <HeaderDiv data={children.header} content={<>The campaign name< br/> [can't edit]</>} />,
    },
    { name: 'startDate', header: 'Start', type: 'string', defaultFlex: 1, editable: false, 
      renderHeader: (children) => <HeaderDiv data={children.header} content={<>Date the campaign starts< br/> [can't edit]</>} />,
    },
    { name: 'endDate', header: 'End', type: 'string', defaultFlex: 1, editable: true, 
      renderHeader: (children) => <HeaderDiv data={children.header} content={<>Date the campaign ends< br/> [can edit]</>} />,
    },
    { name: 'dateCompleted', header: 'Finishes', type: 'string', defaultFlex: 1, editable: false, 
      renderHeader: (children) => <HeaderDiv data={children.header} content={<>Date the campaign is predicted to reach Max Views< br/> [can't edit]</>} />,
    },
    { name: 'reach', header: 'Reach', defaultFlex: 1, type: 'number', editable: true, 
      renderHeader: (children) => <HeaderDiv data={children.header} content={<>% of total users the campaign targets< br/> [can edit]</>} />,
    },
    { name: 'maxViews', header: 'Target', type: 'number', defaultFlex: 1, editable: true, 
      renderHeader: (children) => <HeaderDiv data={children.header} content={<>Number of views the campaign needs to complete< br/> [can edit]</>} />,
    },
    { name: 'totalViews', header: 'Views', type: 'number', defaultFlex: 1, editable: false, 
      renderHeader: (children) => <HeaderDiv data={children.header} content={<>Number of views the campaign has today< br/> [can't edit]</>} />,
    },
    { name: 'complete', header: 'Completed', type: 'number', defaultFlex: 1,  editable: false, 
      renderHeader: (children) => <HeaderDiv data={children.header} content={<>% of max views the campaign has today< br/> [can't edit]</>} />,
    },
    { name: 'boost', header: 'Manual Boost', type: 'number', defaultFlex: 1, editable: true, 
      renderHeader: (children) => <HeaderDiv data={children.header} content={<>Manually defined % boost the campaign has< br/> [can edit]</>} />,
    },
    { name: 'reachBoost', header: 'Small Reach Boost', defaultFlex: 1, type: 'number', editable: false, 
      renderHeader: (children) => <HeaderDiv data={children.header} content={<>% boost the campaign has because it targets less than 100% of users ((100% - Reach %) x Small Reach Multiplier) <br /> [can't edit]</>} />,
    },
    { name: 'totalBoost', header: 'Total Boost', defaultFlex: 1, type: 'number', editable: false, 
      renderHeader: (children) => <HeaderDiv data={children.header} content={<>Total % boost the campaign has (Manual Boost + Small Reach Boost + 30/60/90 Day Boost) <br /> [can't edit]</>} />,
    },

  ]

  useEffect(() => {
    if (filtered) {
      setData(campaigns.filter(camp => filtered.has(camp.id)))
    }
    else {
      setData(campaigns)
    }
  }, [campaigns, filtered])

  const dataSource = data

  const onEditComplete = useCallback(({ value, columnId, rowId, data }) => {
    const camp = campaigns.filter((item) => {
      return item.id === rowId; 
    }).at(0)
    let edited = false;

    switch (columnId) {
      case 'endDate':
        if (camp.endDate !== value) {
          camp.endDate = value
          edited = true
        }
        break;
      case 'maxViews':
        if (camp.maxViews !== parseInt(value)) {
          camp.maxViews = parseInt(value)
          edited = true
        }
        break;
      case 'boost':
        if (camp.boost !== parseInt(value)) {
          camp.boost = parseInt(value) 
          edited = true
        }
        break;
      case 'reach':
        if (camp.reach !== parseInt(value)) {
          camp.reach = parseInt(value)
          edited = true
        }
        break;
      default:
        break;
    }
    if (edited) {
      setSelected(data)
      updateCampaigns(camp)
    }
  }, [campaigns, updateCampaigns])


  const gridStype = { minHeight: 700 }

  return (
    <ReactDataGrid 
      idProperty="id"
      theme="blue-light"
      style={gridStype}
      columns={columns}
      dataSource={dataSource}
      sortable={true}
      enableColumnAutosize={true}
      editable={true}
      defaultSelected={selected}
      enableKeyboardNavigation={false}
      onEditComplete={onEditComplete}
      pagination
      limit={15}
      enableSelection={true}
    />
  )

}


export default CampaignList
