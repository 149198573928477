import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { Form, FormGroup, Input, Label } from "reactstrap";
import InputErrors from "../../commons/components/InputErrors";
import { countries } from "../../commons/constants";

const DeliveryFreePostalCodeForm = ({id, onSave, defaultValues, disabled = false}) => {

  const { register, handleSubmit, setError, setFocus, formState: { errors } } = useForm({
    mode: "onSubmit",
    defaultValues: defaultValues
  });

  useEffect(() => {
    setFocus("postalCode");
  }, [setFocus]);

  const { ref: refCountry, ...registerCountry } = register('country', { required: true });
  const { ref: refPostalCode, ...registerPostalCode } = register('postalCode', { required: true });

  const onSubmit = (data) => {
    onSave(data, setError);
  };

  return (
    <Form id={id} onSubmit={handleSubmit(onSubmit)}>
      <FormGroup>
        <Label for='country'>
          <FormattedMessage id="delivery-free-postal-code.fields.country" />
        </Label>
        <Input id="country" name="country" innerRef={refCountry} {...registerCountry} disabled={disabled} type="select">
          {countries.map(c => <option key={c.value} value={c.value}><FormattedMessage id={c.labelKey} /></option>)}
        </Input>
        <InputErrors fieldName="country" errors={errors} />
      </FormGroup>
      <FormGroup>
        <Label for='postalCode'>
          <FormattedMessage id="delivery-free-postal-code.fields.postalCode" />
        </Label>
        <Input id="startpostalCodeDate" name="postalCode" innerRef={refPostalCode} {...registerPostalCode} disabled={disabled} />
        <InputErrors fieldName="postalCode" errors={errors} />
      </FormGroup>
    </Form>
  );
};

export default DeliveryFreePostalCodeForm;