
export const web_seasonality = 
	{
			1:		1.1841,
				2:		1.8501,
				3:		1.8039,
				4:		2.231,
				5:		1.9457,
				6:		1.5777,
				7:		1.1224,
				8:		1.1122,
				9:		1.1466,
				10:		1.2121,
				11:		1.5002,
				12:		1.4162,
				13:		1.3385,
				14:		0.9787,
				15:		0.7355,
				16:		1.2454,
				17:		1.0384,
				18:		1.0303,
				19:		0.9324,
				20:		0.8751,
				21:		0.6928,
				22:		0.6827,
				23:		0.7307,
				24:		0.6611,
				25:		0.7809,
				26:		0.7477,
				27:		0.6516,
				28:		0.4813,
				29:		0.4792,
				30:		0.5599,
				31:		0.5814,
				32:		0.4755,
				33:		0.5141,
				34:		0.3701,
				35:		0.3634,
				36:		0.5374,
				37:		0.5209,
				38:		0.7415,
				39:		0.7415,
				40:		0.8647,
				41:		0.8005,
				42:		0.7197,
				43:		0.7524,
				44:		1.0286,
				45:		1.0877,
				46:		1.4337,
				47:		1.2476,
				48:		1.3057,
				49:		1.3179,
				50:		0.9411,
				51:		1.1861,
				52:		1.3437,
				53:		1.4759,
				54:		1.1577,
				55:		1.0317,
				56:		0.7098,
				57:		0.7056,
				58:		0.8283,
				59:		0.8645,
				60:		1.1078,
				61:		1.1989,
				62:		0.8303,
				63:		0.7031,
				64:		0.725,
				65:		0.9481,
				66:		0.961,
				67:		1.0896,
				68:		0.9487,
				69:		0.9326,
				70:		0.7202,
				71:		0.8666,
				72:		1.0095,
				73:		1.0699,
				74:		1.049,
				75:		1.0451,
				76:		0.8659,
				77:		0.6925,
				78:		0.6561,
				79:		0.9327,
				80:		0.7733,
				81:		0.804,
				82:		1.0368,
				83:		0.887,
				84:		0.7554,
				85:		0.6496,
				86:		0.7819,
				87:		0.9547,
				88:		0.9909,
				89:		0.8893,
				90:		0.8156,
				91:		0.7966,
				92:		0.6728,
				93:		0.8168,
				94:		0.7828,
				95:		0.8349,
				96:		0.7289,
				97:		0.6934,
				98:		0.4975,
				99:		0.5122,
				100:		0.5764,
				101:		0.7032,
				102:		0.7909,
				103:		0.6422,
				104:		0.6164,
				105:		0.543,
				106:		0.4639,
				107:		0.6814,
				108:		0.7394,
				109:		0.9531,
				110:		0.7821,
				111:		0.6792,
				112:		0.5713,
				113:		0.5919,
				114:		0.8047,
				115:		0.733,
				116:		0.886,
				117:		1.0193,
				118:		0.9516,
				119:		0.9096,
				120:		0.617,
				121:		0.6164,
				122:		1.1296,
				123:		1.1626,
				124:		1.3531,
				125:		1.342,
				126:		1.465,
				127:		1.3172,
				128:		1.3849,
				129:		1.9348,
				130:		1.7704,
				131:		1.6365,
				132:		1.5823,
				133:		1.3647,
				134:		1.2732,
				135:		1.7499,
				136:		2.1234,
				137:		2.4061,
				138:		1.9737,
				139:		2.3478,
				140:		1.9862,
				141:		1.6547,
				142:		2.0504,
				143:		2.1422,
				144:		2.1381,
				145:		2.2403,
				146:		1.8807,
				147:		1.5885,
				148:		0.7811,
				149:		0.8834,
				150:		2.0367,
				151:		1.9777,
				152:		1.7386,
				153:		1.5936,
				154:		1.4056,
				155:		1.019,
				156:		1.243,
				157:		1.4062,
				158:		1.5509,
				159:		1.3464,
				160:		1.2834,
				161:		1.0374,
				162:		0.7115,
				163:		0.9932,
				164:		1.0509,
				165:		0.991,
				166:		0.9029,
				167:		0.9179,
				168:		0.6568,
				169:		0.4881,
				170:		0.815,
				171:		0.6728,
				172:		0.5499,
				173:		0.5806,
				174:		0.4798,
				175:		0.312,
				176:		0.1727,
				177:		0.2284,
				178:		0.3351,
				179:		0.2877,
				180:		0.2485,
				181:		0.1687,
				182:		0.1787,
				183:		0.2112,
				184:		0.2011,
				185:		0.2884,
				186:		0.2663,
				187:		0.2621,
				188:		0.2623,
				189:		0.1639,
				190:		0.168,
				191:		0.2209,
				192:		0.3112,
				193:		0.2905,
				194:		0.321,
				195:		0.2515,
				196:		0.1697,
				197:		0.1604,
				198:		0.1871,
				199:		0.1639,
				200:		0.228,
				201:		0.1928,
				202:		0.1505,
				203:		0.1981,
				204:		0.143,
				205:		0.1575,
				206:		0.2018,
				207:		0.2369,
				208:		0.2191,
				209:		0.1565,
				210:		0.1163,
				211:		0.1896,
				212:		0.2596,
				213:		0.2411,
				214:		0.2037,
				215:		0.2104,
				216:		0.2441,
				217:		0.1834,
				218:		0.1027,
				219:		0.169,
				220:		0.1925,
				221:		0.2292,
				222:		0.217,
				223:		0.1767,
				224:		0.1415,
				225:		0.1396,
				226:		0.1332,
				227:		0.2623,
				228:		0.372,
				229:		0.3479,
				230:		0.3615,
				231:		0.1951,
				232:		0.137,
				233:		0.1759,
				234:		0.2158,
				235:		0.1702,
				236:		0.1452,
				237:		0.1587,
				238:		0.2073,
				239:		0.1448,
				240:		0.3143,
				241:		0.2186,
				242:		0.16,
				243:		0.1656,
				244:		0.0966,
				245:		0.0989,
				246:		0.1128,
				247:		0.1668,
				248:		0.1955,
				249:		0.3195,
				250:		0.2256,
				251:		0.1363,
				252:		0.098,
				253:		0.146,
				254:		0.1468,
				255:		0.1588,
				256:		0.2752,
				257:		0.2722,
				258:		0.3142,
				259:		0.2612,
				260:		0.5456,
				261:		0.5882,
				262:		0.7927,
				263:		0.8302,
				264:		0.9924,
				265:		0.7101,
				266:		0.7488,
				267:		0.5905,
				268:		0.9558,
				269:		1.4328,
				270:		1.5993,
				271:		1.5981,
				272:		1.5155,
				273:		1.329,
				274:		1.1847,
				275:		1.689,
				276:		2.0313,
				277:		1.8916,
				278:		1.758,
				279:		1.6258,
				280:		1.7454,
				281:		1.541,
				282:		1.7748,
				283:		2.1459,
				284:		1.9431,
				285:		1.6959,
				286:		1.5443,
				287:		1.1066,
				288:		1.3117,
				289:		1.6984,
				290:		1.8832,
				291:		1.6601,
				292:		1.5537,
				293:		1.3831,
				294:		1.3124,
				295:		1.1,
				296:		1.5429,
				297:		1.439,
				298:		1.2863,
				299:		1.2608,
				300:		1.1593,
				301:		1.036,
				302:		0.9802,
				303:		0.9753,
				304:		1.2704,
				305:		1.1537,
				306:		1.3646,
				307:		1.2803,
				308:		0.9927,
				309:		0.8902,
				310:		1.1384,
				311:		1.5377,
				312:		1.3489,
				313:		1.4158,
				314:		1.1497,
				315:		0.9052,
				316:		0.8271,
				317:		1.0693,
				318:		1.3901,
				319:		1.5852,
				320:		1.4069,
				321:		1.5095,
				322:		1.1444,
				323:		0.9396,
				324:		1.3619,
				325:		2.0781,
				326:		1.6325,
				327:		1.8107,
				328:		1.3969,
				329:		1.1116,
				330:		1.0337,
				331:		1.1671,
				332:		1.4737,
				333:		1.7351,
				334:		1.7807,
				335:		1.4168,
				336:		1.4622,
				337:		1.182,
				338:		1.3063,
				339:		1.5979,
				340:		1.6687,
				341:		1.6591,
				342:		1.689,
				343:		1.5811,
				344:		1.2148,
				345:		1.5294,
				346:		2.1008,
				347:		2.2142,
				348:		2.3059,
				349:		2.3907,
				350:		1.8416,
				351:		1.6444,
				352:		2.062,
				353:		2.8955,
				354:		3.5542,
				355:		3.7215,
				356:		3.2051,
				357:		2.5016,
				358:		1.7176,
				359:		1.6787,
				360:		2.5615,
				361:		3.1345,
				362:		2.6993,
				363:		2.7902,
				364:		2.0025,
				365:		1.1687,
}
