import React, { Fragment, useEffect, useState } from "react"
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, Row, Spinner } from "reactstrap";
import AsyncSelect from 'react-select/async';
import CampaignService from "../../campaigns/services/CampaignService";
import { Controller, useForm } from "react-hook-form";
import InputErrors from "../../commons/components/InputErrors";
import ReportingService from "../services/ReportingService";
import { toast } from 'react-toastify';
import { FormattedMessage, useIntl } from "react-intl";
import { DEFAULT_MAX_LENGTH } from "../../commons/validations";
import { openDatePickerOnClick } from "../../commons/functions";
import { Link, useLocation } from "react-router-dom";
import moment from "moment";

const maxShowedCampaigns = 20;
const defaultFormValues = {
  "name": "",
  "startDate": "",
  "endDate": "",
  "mailCampaigns": [],
  "webCampaigns": [],
  "printBannerCampaigns": [],
  "printFullPageCampaigns": []
}
const hideMailCampaigns = true;// FIXME Add this moment we cannot do anything with mail campaigns, not enough integration. So we will hide it.

const EvaluationReportPage = (props) => {

  const [sendgridCampaigns, setSendgridCampaigns] = useState([]);
  const [loadingCampaigns, setLoadingCampaigns] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const intl = useIntl();
  const { search } = useLocation();

  useEffect(() => {
    
    if (!hideMailCampaigns) {
      CampaignService.sendgrid().then(response => {
        setSendgridCampaigns(response.data);
      }).finally(() => setLoadingCampaigns(false));
    }

  }, []);

  const { control, setError, clearErrors, register, handleSubmit, reset, formState: { errors } } = useForm({
    mode: "onSubmit"
  });

  const { ref: refName, ...registerName } = register('name', { required: true, maxLength: DEFAULT_MAX_LENGTH });
  const { ref: refStartDate, ...registerStartDate } = register('startDate', { required: true });
  const { ref: refEndDate, ...registerEndDate } = register('endDate', { required: true, validate: (value, formValues) => {

    if (formValues["startDate"]) {
      const startDate = new Date(formValues["startDate"]);
      const endDate = new Date(value);

      if (startDate >= endDate) {
        return intl.formatMessage({id: "validations.field.biggerThan", values: {
          "field": intl.formatMessage({id: "reports.evaluationReport.field.endDate"}),
          "otherField": intl.formatMessage({id: "reports.evaluationReport.field.startDate"})
        }});
      }
      
    }

    return null;

  }});
  
  const onSubmit = (data) => {
    
    if ((!data.webCampaigns || data.webCampaigns.length === 0)
        && (!data.printBannerCampaigns || data.printBannerCampaigns.length === 0)
        && (!data.printFullPageCampaigns || data.printFullPageCampaigns.length === 0)
        && (!hideMailCampaigns && (!data.mailCampaigns || data.mailCampaigns.length === 0))) {

        setError("campaigns", {"type": "manual", "message": intl.formatMessage({id: "reports.evaluationReport.msg.someCampaignsMustBeSelected"})});
        return;
    }
    
    setSubmitting(true);

    ReportingService.evaluationReport(data)
    .then(response => {
      toast.success(intl.formatMessage({id: "reports.evaluationReport.msg.success"}));
      reset(defaultFormValues);
    })
    .catch(error => {
      toast.error(intl.formatMessage({id: "reports.evaluationReport.msg.error"}));
    })
    .finally(() => setSubmitting(false));
  };

  const loadWebCampaignsOptions = (inputValue, callback) => {

    if (inputValue?.length >= 3) {
      CampaignService.list(0, maxShowedCampaigns, {basic: true, name: inputValue, type: "WEB"}).then(response => {
        callback(response.data.data);
      });
    } else {
      callback([]);
    }

  };

  const loadPrintBannerCampaignsOptions = (inputValue, callback) => {

    if (inputValue?.length >= 3) {
      CampaignService.list(0, maxShowedCampaigns, {basic: true, name: inputValue, type: "PRINT"}).then(response => {
        callback(response.data.data);
      });
    } else {
      callback([]);
    }

  };

  const loadPrintFullPageCampaignsOptions = (inputValue, callback) => {

    if (inputValue?.length >= 3) {
      CampaignService.list(0, maxShowedCampaigns, {basic: true, name: inputValue, type: "PRINT"}).then(response => {
        callback(response.data.data);
      });
    } else {
      callback([]);
    }

  };

  const filterSendgridOptions = (inputValue, callback) => {
    if (inputValue?.length >= 3) {
      const filtered = sendgridCampaigns.filter(item => item.title.toLowerCase().includes(inputValue.toLowerCase())).slice(0, maxShowedCampaigns)
      callback(filtered);
    } else {
      callback([]);
    }
  }

  const noOptionsMessage = (input) => {
    if (input.inputValue.length === 0) {
        return "Type to search";
    } else if(input.inputValue.length < 3) {
        return "Search input must be at least 3 characters";
    } else {
        return "No options";
    }
  }


  let sendgridComponent = null;

  if (!hideMailCampaigns) {
  if (loadingCampaigns === true) {
    sendgridComponent = (
      "Loading mail campaigns"
    );
    } else {
      sendgridComponent = (
        <FormGroup>
          <Label htmlFor="selectMailCampaigns">Mail campaigns</Label>
          <Controller control={control} name="mailCampaigns"
            render={({field: {onChange, value, name, ref}}) => {

              return <AsyncSelect
                innerRef={ref}
                loadOptions={filterSendgridOptions}
                getOptionLabel={c => c.title}
                getOptionValue={c => c.id}
                isMulti
                loadingMessage={() => "Loading mail campaigns"}
                noOptionsMessage={noOptionsMessage}
                placeholder="Type to filter mail campaigns"
                inputId="selectMailCampaigns"
                onChange={e => {
                  onChange(e);
                  clearErrors("campaigns");
                }}
                value={value}
                classNamePrefix="zc-select" />
            }}>
            
          </Controller>
          <InputErrors fieldName="mailCampaigns" errors={errors} />
        </FormGroup>
      );
    }
  }

  const maxDate = moment().format("YYYY-MM-DD");
  let minDate = moment().set("M", 8).set("D", 1);

  if (moment().isBefore(minDate)) {
    minDate = minDate.subtract(1, "year");
  }

  minDate = minDate.format("YYYY-MM-DD");

  console.log(minDate);

  return (
    <Fragment>
      {submitting ? <Loader /> : null}
      <Form id="evaluationReportForm" onSubmit={handleSubmit(onSubmit)}>
          
          <Row>
            <Col xs={12} md={4} lg={6}>
              <FormGroup>
                <Label htmlFor="name">
                  <FormattedMessage id="reports.evaluationReport.field.name" />
                </Label>
                <Input id="name" type="text" innerRef={refName} {...registerName} />
                <InputErrors fieldName="name" errors={errors} />
              </FormGroup>
            </Col>
            <Col xs={12} md={4} lg={3}>
              <FormGroup>
                <Label htmlFor="startDate">
                  <FormattedMessage id="reports.evaluationReport.field.startDate" />
                </Label>
                <Input id="startDate" type="date" innerRef={refStartDate} {...registerStartDate} onClick={openDatePickerOnClick} max={maxDate} min={minDate} />
                <InputErrors fieldName="startDate" errors={errors} />
              </FormGroup>
            </Col>
            <Col xs={12} md={4} lg={3}>
              <FormGroup>
                <Label htmlFor="endDate">
                  <FormattedMessage id="reports.evaluationReport.field.endDate" />
                </Label>
                <Input id="endDate" type="date" innerRef={refEndDate} {...registerEndDate} onClick={openDatePickerOnClick} max={maxDate} min={minDate} />
                <InputErrors fieldName="endDate" errors={errors} />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroup>
                <Label htmlFor="selectWebCampaigns">
                  <FormattedMessage id="reports.evaluationReport.field.webCampaigns" />
                </Label>
                <Controller control={control} name="webCampaigns"
                  render={({field: {onChange, value, name, ref}}) => {

                    return <AsyncSelect loadOptions={loadWebCampaignsOptions}
                      innerRef={ref}
                      getOptionLabel={c => c.name + " (" + c.type + ")"}
                      getOptionValue={c => c.id}
                      isMulti
                      loadingMessage={() => "Loading campaigns"}
                      noOptionsMessage={noOptionsMessage}
                      placeholder="Type to filter web and printing campaigns"
                      inputId="selectWebCampaigns"
                      onChange={e => {
                        onChange(e);
                        clearErrors("campaigns");
                      }}
                      value={value}
                      classNamePrefix="zc-select"
                      />

                  }}>
          
                </Controller>
                <InputErrors fieldName="webCampaigns" errors={errors} />
              </FormGroup>
            </Col>
            <Col>
              {sendgridComponent}
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroup>
                <Label htmlFor="selectPrintBannerCampaigns">
                  <FormattedMessage id="reports.evaluationReport.field.bannerCampaigns" />
                </Label>
                <Controller control={control} name="printBannerCampaigns"
                  render={({field: {onChange, value, name, ref}}) => {

                    return <AsyncSelect loadOptions={loadPrintBannerCampaignsOptions}
                      innerRef={ref}
                      getOptionLabel={c => c.name + " (" + c.type + ")"}
                      getOptionValue={c => c.id}
                      isMulti
                      loadingMessage={() => "Loading campaigns"}
                      noOptionsMessage={noOptionsMessage}
                      placeholder="Type to filter web and printing campaigns"
                      inputId="selectPrintBannerCampaigns"
                      onChange={e => {
                        onChange(e);
                        clearErrors("campaigns");
                      }}
                      value={value}
                      classNamePrefix="zc-select"
                      />

                  }}>
          
                </Controller>
                <InputErrors fieldName="printBannercampaigns" errors={errors} />
              </FormGroup>
            </Col>
            <Col>
            <FormGroup>
                <Label htmlFor="selectPrintFullPageCampaigns">
                  <FormattedMessage id="reports.evaluationReport.field.fullPageCampaigns" />
                </Label>
                <Controller control={control} name="printFullPageCampaigns"
                  render={({field: {onChange, value, name, ref}}) => {

                    return <AsyncSelect loadOptions={loadPrintFullPageCampaignsOptions}
                      innerRef={ref}
                      getOptionLabel={c => c.name + " (" + c.type + ")"}
                      getOptionValue={c => c.id}
                      isMulti
                      loadingMessage={() => "Loading campaigns"}
                      noOptionsMessage={noOptionsMessage}
                      placeholder="Type to filter web and printing campaigns"
                      inputId="selectPrintFullPageCampaigns"
                      onChange={e => {
                        onChange(e);
                        clearErrors("campaigns");
                      }}
                      value={value}
                      classNamePrefix="zc-select"
                      />

                  }}>
          
                </Controller>
                <InputErrors fieldName="printFullPageCampaigns" errors={errors} />
              </FormGroup>
            </Col>
          </Row>
          
          <Row>
            <Col>
              <Button color="primary">
                  <FormattedMessage id="reports.evaluationReport.buttons.generate" />
                </Button>
              <Link to={`/reporting${search}`}  className="btn btn-outline-secondary ms-2">
                <FormattedMessage id="buttons.cancel" />
              </Link>
              {errors.campaigns ? <div className="d-inline-block ms-2"><InputErrors fieldName="campaigns" errors={errors} /></div> : null }
            </Col>
          </Row>
      </Form>
    </Fragment>
  );
};

export default EvaluationReportPage;

const Loader = () => {

  return (
    <Modal isOpen={true} centered>
      <ModalBody>
        <div className="text-center">
          <Spinner />
          <div className="mt-3">
            Waiting server response
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}