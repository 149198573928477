import moment from "moment";
import DaysRemainingService from "./DaysRemainingService";
import { web_seasonality } from "../data/web_seasonality";
import { print_seasonality } from "../data/print_seasonality"; 

const SimulateAlgo = (campaigns, config) => {
  return simulate([], campaigns, campaigns, 0, config);
}

const simulate = (simulatedData, campaigns, originals, day, config) => {
  if (day >= config.days - 1) {
    return simulatedData;
  }
  const campaignsToday = JSON.parse(JSON.stringify(campaigns))
  const today = moment().add(day, 'days')

  for (let i = 0; i < campaignsToday.length; i++) {
    let camp = campaignsToday.at(i)
    camp.daysRemaining = camp.DaysRemaining - 1
    const daysLeft =  DaysRemainingService(day, camp.endDate, camp.type)
    if (camp.daysRemining <= 0 || daysLeft <= 0) {
      camp.active = false;
    }
    else {
      camp.active = true;
    }
    if (camp.viewsYesterday && camp.viewsYesterday !== 0) {
      camp.totalViews += camp.viewsYesterday 
      camp.complete = Math.min(1, camp.totalViews / camp.maxViews) * 100
      if (camp.complete >= 100) {
        originals[i].dateCompleted = today.format('YYYY/MM/DD')
        if (camp.viewsYesterday === 0) {
          camp.complete = NaN
        }
      }
    }

    if (!camp.active || camp.complete >= 100) {
      if (!camp.active) {
        camp.complete = NaN
      }
      if (!camp.completed >= 100) {
        if (day === config.days - 2) {
          originals[i].dateCompleted = "N/A"
        }
        else {
          originals[i].dateCompleted = "INCOMPLETE"
        }
      }
      camp.viewsToday = 0.0;
      camp.percentViewsToday = 0.0;
      camp.viewsYesterday = 0.0;
    }
    else {
      const multiplier = calculateMultiplier(camp, daysLeft, config);
      const viewsRemaining = Math.max(0, camp.maxViews - camp.totalViews)
      camp.viewsToday = Math.log(Math.max(1.5, viewsRemaining / daysLeft * multiplier * (camp.reach / 100.0))) 
    }
  }
  let totalViewsTodayWeb = 0
  let totalViewsTodayPrint = 0
  campaignsToday.forEach(camp => {
    if (camp.type === "WEB") {
      totalViewsTodayWeb += camp.viewsToday
    }
    else {
      totalViewsTodayPrint += camp.viewsToday
    }
  })

  campaignsToday.forEach(camp => {
    if (camp.viewsToday !== 0) {
      camp.percentViewsToday = camp.viewsToday / (camp.type === "WEB" ? totalViewsTodayWeb : totalViewsTodayPrint) * 100
      camp.viewsYesterday = Math.max(1, Math.floor((camp.type === "WEB" ? config.avgViewsWeb * web_seasonality[today.dayOfYear()] : config.avgViewsPrint * print_seasonality[today.dayOfYear()]) * (camp.percentViewsToday / 100)))
    }
  })
  
  simulatedData.push(campaignsToday)

  return simulate(simulatedData, campaignsToday, originals, day + 1, config)
}

const calculateMultiplier = (camp, daysLeft, config) => {
  let x = 1 + (camp.totalBoost / 100);
  
  if (daysLeft <= 30) {
    x += config.boost30
  }
  else if (daysLeft <= 60) {
    x += config.boost60
  }
  else if (daysLeft <= 90) {
    x += config.boost90
  }

  return x * 1000;
}

export default SimulateAlgo
