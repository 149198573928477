import React, { useState, useEffect } from "react";
import { stringToColor } from "../../commons/functions";
import { Chart } from 'react-google-charts';
import moment from "moment";

const CampaignGraph = ({simulationData, filtered, lastEdited, startDate, endDate }) => {
  const [graphData, setGraphData] = useState();
  const [chartWrapper, setChartWrapper] = useState();
  const [series, setSeries] = useState();

  useEffect(() => {
    if (simulationData) {
      const graphData = [];
      const labels = [];
      labels.push("Date")
      simulationData[0]
        .filter(campaign => filtered ? filtered.has(campaign.id) : true)
        .forEach(campaign => labels.push(campaign.name))
      graphData.push(labels);
      for (let i = 0; i < simulationData.length; i++) {
        const data = [];
        const today = moment().add(i, 'days')
        if (today.isSameOrAfter(startDate ? startDate : today) && today.isSameOrBefore(endDate ? endDate : today)) {
          data.push(moment().add(i, 'days').format('D-MMM'))
          for (let j = 0; j < simulationData[i].length; j++) {
            const campaign = simulationData[i][j]
            if (!filtered || filtered.has(campaign.id)) {
              data.push( campaign.complete )
            }
          }
          graphData.push(data)
        }
      }
      setGraphData(graphData)
    }
  }, [simulationData, filtered, startDate, endDate])

  useEffect(() => {
    if (graphData) {
      let series = {};
      const labels = graphData[0]
      for (let i = 0; i < labels.length; i++) {
        series[i - 1] = { color: stringToColor(labels[i])}
        if (lastEdited && labels[i] === lastEdited.name) {
          series[i - 1] = { color: stringToColor(labels[i]), lineWidth: 15}
        }
      }
      setSeries(series);
    }
  }, [graphData, lastEdited])

  const redrawChart = () => {
    if (chartWrapper) {
      chartWrapper.draw()
    }
  }

  window.addEventListener('resize', redrawChart);

  const options = {  
    width: "95%",
    height: 800,
    curveType: 'none',
    hAxis: {
      title: "Date",
    },
    vAxis: {
      baseline: 0,
      maxValue: 100,
      title: "% Completed",
    },
    title: "Campaign Completion (% of Max Views)",
    legend: { position: "right" },
    series: series,
    tooltip: {
      showColorCode: true,
    },
    chartArea: {
      left: 55,
      top: 50,
      width: '75%',
      height: '80%'
    },
    crosshair: {
      trigger: 'focus',
      orientation: 'both',
    },
    getChartWrapper: () => {
      setChartWrapper(this.chartWrapper)
    }
  };

  return (
      <Chart 
        chartType="LineChart"
        data={graphData}
        options={options}
      /> 
  )
}

export default CampaignGraph
