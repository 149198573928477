import React, { useState } from "react"
import { Col, Container, Row } from "reactstrap";
import { Page } from "../../commons/components/Page";
import DeliveryConfigList from "../components/DeliveryConfigList";
import DeliveryConfigRangeList from "../components/DeliveryConfigRangeList";

const DeliveryConfigurationPage = (props) => {

  const [selected, setSelected] = useState(null);

  return (
    <Page>
      <Container fluid>
        <Row>
          <Col>
            <DeliveryConfigList onSelect={setSelected} selected={selected}/>
          </Col>
        </Row>
        <Row>
          <Col>
            <DeliveryConfigRangeList configId={selected} />
          </Col>
        </Row>
      </Container>
    </Page>
  );
};

export default DeliveryConfigurationPage;