import React from "react"
import { Container } from "reactstrap";
import { Page } from "../../commons/components/Page";
import AlgoForm from "../components/AlgoForm";

const AlgoPage = () => {

  return (
    <Page>
      <Container fluid>
        <AlgoForm />
      </Container>
    </Page>
  );
};

export default AlgoPage;
