import React, { Fragment, useEffect, useState } from "react";
import CustomSpinner from "../../commons/components/Spinner";
import { FormattedMessage, useIntl } from "react-intl";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import ListPagination from "../../commons/components/ListPagination";
import DataTable from "../../commons/components/DataTable";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import WarningMessage from "../../commons/components/WarningMessage";
import { toast } from 'react-toastify';
import { handleApiErrors, handleErrors } from "../../commons/functions";
import DeliveryFreePostalCodeService from "../services/DeliveryFreePostalCodeService";
import DeliveryFreePostalCodeForm from "./DeliveryFreePostalCodeForm";
import { default_country } from "../../commons/constants";

const dataTableColumns = [
  {
    headerKey: "delivery-free-postal-code.fields.country",
    selector: row => <FormattedMessage id={`countries.${row.country}`} />
  },
  {
    headerKey: "delivery-free-postal-code.fields.postalCode",
    selector: row => row.postalCode
  }
];

const DeliveryFreePostalCodeList = () => {

  const pageSize = 10;

  const [currentPage, setCurrentPage] = useState(0);
  const [page, setPage] = useState({total: 0, list: []});
  const [selectedRow, setSelectedRow] = useState({country: default_country});
  const [openForm, setOpenForm] = useState(false);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);

  const intl = useIntl();

  const result = useQuery({
    queryKey: ["delivery-free-postal-code-list", currentPage, pageSize], 
    queryFn: () => DeliveryFreePostalCodeService.list(currentPage, pageSize),
    onSuccess: (response) => setPage({total: response.data.total, list: response.data.data })
  });

  const addMutation = useMutation(([data, setError]) => DeliveryFreePostalCodeService.create(data), {
    onSuccess: (response, vars, context) => {
      toast.success(intl.formatMessage({id: "delivery-free-postal-code.msg.create"}));
      result.refetch();
      toggleForm();
    },
    onError: (error, [data, setError], context) => {
      handleErrors(error, "delivery-free-postal-code.msg.error.create", setError, toast, intl);
    }
  });

  const deleteMutation = useMutation((identifier) => DeliveryFreePostalCodeService.delete(identifier), {
    onSuccess: (response, [identifier], context) => {
      toast.success(intl.formatMessage({id: "delivery-free-postal-code.msg.delete"}));
      result.refetch();
    },
    onError: (error, vars, context) => toast.error(handleApiErrors(intl, error, "delivery-free-postal-code.msg.error.delete")),
    onSettled: () => {
      toggleDelete();
    }
  });

  const updateMutation = useMutation(([identifier, data, setError]) => DeliveryFreePostalCodeService.update(identifier, data), {
    onSuccess: (response, vars, context) => {
      toast.success(intl.formatMessage({id: "delivery-free-postal-code.msg.update"}));
      result.refetch();
      toggleForm();
    },
    onError: (error, [identifier, data, setError], context) => {
      handleErrors(error, "delivery-free-postal-code.msg.error.update", setError, toast, intl);
    }
  });

  useEffect(() => {
    if (!openForm) {
      setSelectedRow(null);
    }
  }, [openForm]);

  useEffect(() => {
    if (!openDeleteConfirm) {
      setSelectedRow(null);
    }
  }, [openDeleteConfirm]);

  const onPageChange = (e, pageTotLoad) => {
    e.preventDefault();

    if (result.isLoading) {
      return;
    }

    setCurrentPage(pageTotLoad);
  }

  const toggleForm = () => {
    setOpenForm(oldValue =>!oldValue);
  };

  const toggleDelete = () => {
    setOpenDeleteConfirm(oldValue =>!oldValue);
  };

  const handleSave = (data, setError) => {
    if (selectedRow) {
      updateMutation.mutate([selectedRow.id, data, setError]);
    } else {
      addMutation.mutate([data, setError]);
    }
  };

  const handleDelete = () => {
    deleteMutation.mutate([selectedRow.id]);
  };

  const handleConfirmDelete = (row) => {
    setSelectedRow(row);
    toggleDelete();
  };

  const handleUpdate = (row) => {
    setSelectedRow(row);
    toggleForm();
  }

  let content = null;

  if (result.isLoading) {
    content = (
      <CustomSpinner messageKey="delivery-free-postal-code.loading" />
    );
  } else if (result.isError) {
    content = (
      <FormattedMessage id="delivery-free-postal-code.loading.error" />
    );
  } else {

    const pagination = <ListPagination page={currentPage} totalItems={page.total} pageSize={pageSize} onPageChange={onPageChange} showSinglePage={false} />;

    content = (
      <Fragment>
        <DataTable responsive bordered hover className='delivery-free-postal-code-table' data={page.list} columns={dataTableColumns}
          actionsHeaderStyle={{width: "60px"}} renderRowActions={(row, rowIndex) => {
           
            return (
              <Fragment>
                <i className="fas fa-edit me-2" role="button" onClick={() => handleUpdate(row)}></i>
                <i className="fas fa-trash-alt" role="button" onClick={() => handleConfirmDelete(row)}></i>
              </Fragment>
            );

          }}/>
        {pagination}
      </Fragment>
    );

  }

  return (
    <div>
      <div className='d-flex justify-content-between'>
        <h2 className='section-title fs-5 flex-grow-1'><FormattedMessage id="delivery-free-postal-code" /></h2>
        <Button className='ms-2' size='sm' disabled={result.isLoading || addMutation.isLoading || updateMutation.isLoading} onClick={toggleForm}>
          <i className='fas fa-plus me-0 me-md-2' />
          <span className='d-none d-md-inline'>
            <FormattedMessage id="delivery-free-postal-code.buttons.add" />
          </span>
        </Button>
      </div>
      <div className='section-content'>
        {content}
      </div>
      {openDeleteConfirm ? <DeleteModal open={openDeleteConfirm} toggle={toggleDelete} onConfirm={handleDelete} disabled={deleteMutation.isLoading} /> : null}
      {openForm ? <ModalForm open={openForm} toggle={toggleForm} onSave={handleSave} data={selectedRow} disabled={addMutation.isLoading || updateMutation.isLoading}
        headerKey={selectedRow ? "delivery-free-postal-code.edit": "delivery-free-postal-code.add"} /> : null }
    </div>
  );
};

export default DeliveryFreePostalCodeList;

const DeleteModal = ({open, toggle, onConfirm, disabled = false}) => {

  return (
    <Modal isOpen={open} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>
        <FormattedMessage id="confirm" />
      </ModalHeader>
      <ModalBody>
        <WarningMessage messageKey="delivery-free-postal-code.delete.warning" />
        <FormattedMessage id="delivery-free-postal-code.delete" />
      </ModalBody>
      <ModalFooter>
        <div className='d-flex justify-content-end'>
          <Button color='danger' onClick={onConfirm} disabled={disabled}>
            <FormattedMessage id="delivery-free-postal-code.buttons.delete" />
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

const ModalForm = ({open, toggle, headerKey, data, disabled = false, onSave}) => {
  return (
    <Modal isOpen={open} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>
        <FormattedMessage id={headerKey} />
      </ModalHeader>
      <ModalBody>
        {data?.id ? <WarningMessage messageKey="delivery-free-postal-code.edit.warning" /> : null}
        <DeliveryFreePostalCodeForm id="delivery-free-postal-code-form" onSave={onSave} defaultValues={data} disabled={disabled} />
      </ModalBody>
      <ModalFooter>
      <div className='d-flex justify-content-end'>
        <Button color='primary' disabled={disabled} type="submit" form="delivery-free-postal-code-form">
          <FormattedMessage id="delivery-free-postal-code.buttons.save" />
        </Button>
      </div>
      </ModalFooter>
    </Modal>
  );
};
