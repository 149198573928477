import moment from "moment";
import { web_seasonality } from "../data/web_seasonality";
import { print_seasonality } from "../data/print_seasonality";

const DaysRemainingService = (day, campEndDate, type) => {

  let days;

  const data = type === "WEB" ? web_seasonality : print_seasonality;

  const today = moment().add(day, 'days')

  const endDate = moment(campEndDate, "YYYY/MM/DD");

  const today_day_of_year = today.dayOfYear()
  const endDate_day_of_year = endDate.dayOfYear()
  const diff_years = endDate.year() - today.year()

  if (diff_years === 0) {
    days = sumValues(data, today_day_of_year, endDate_day_of_year)
  }

  else {
    days = sumValues(data, today_day_of_year, 365) + sumValues(data, 1, endDate_day_of_year) + (365 * (diff_years - 1))
  }

  return days

}

const sumValues = (data, start, end) => {

  const finish = end === 366 ? 365 : end;

  let sum = 0.0;
  for (let i = start; i <= finish; i++) {
    sum += data[i]
  }

  return sum;
}

export default DaysRemainingService
