import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Moment from "react-moment";
import { Fragment, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import SharedDocumentService from "../services/SharedDocumentService";
import { FormattedMessage, useIntl } from "react-intl";
import CustomSpinner from "../../commons/components/Spinner";
import { handleApiErrors } from "../../commons/functions";
import { toast } from "react-toastify";
import ListPagination from "../../commons/components/ListPagination";
import DataTable from "../../commons/components/DataTable";
import WarningMessage from "../../commons/components/WarningMessage";

const dataTableColumns = [
  {
    headerKey: "shared-documents.fields.name",
    selector: row => (
      <Fragment>
        <div>{row.name}</div>
        <div className="text-muted small">{row.description}</div>
      </Fragment>
    )
  },
  {
    headerKey: "shared-documents.fields.category",
    selector: row => <FormattedMessage id={`shared-documents.fields.category.${row.category}`} />
  },
  {
    headerKey: "shared-documents.fields.sharedTimes",
    headerClassName: "text-end",
    className: "text-end",
    selector: row => row.sharedTimes
  },
  {
    headerKey: "shared-documents.fields.pages",
    headerClassName: "text-end",
    className: "text-end",
    selector: row => row.pages
  },
  {
    headerKey: "shared-documents.fields.sharedDate",
    selector: row => <Moment format='DD/MM/YYYY' parse="DD/MM/YYYY hh:mm:ss">{row.created}</Moment>
  }
];

const SharedDocumentList = () => {

  const pageSize = 10;

  const [currentPage, setCurrentPage] = useState(0);
  const [page, setPage] = useState({total: 0, list: []});
  const [selectedRow, setSelectedRow] = useState(null);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);

  const intl = useIntl();

  const result = useQuery(["shared-documents-list", currentPage, pageSize], () => SharedDocumentService.list(currentPage, pageSize), {
    onSuccess: (response) => setPage({total: response.data.total, list: response.data.data })
  });

  const deleteMutation = useMutation((identifier) => SharedDocumentService.delete(identifier), {
    onSuccess: (response, [identifier], context) => {
      toast.success(intl.formatMessage({id: "shared-documents.msg.delete"}));
      result.refetch();
    },
    onError: (error, vars, context) => toast.error(handleApiErrors(intl, error, "shared-documents.msg.error.delete")),
    onSettled: () => {
      toggleDelete();
    }
  });

  const onPageChange = (e, pageTotLoad) => {
    e.preventDefault();

    if (result.isLoading) {
      return;
    }

    setCurrentPage(pageTotLoad);
  }

  const toggleDelete = () => {
    setOpenDeleteConfirm(oldValue =>!oldValue);
  };

  const handleDelete = () => {
    deleteMutation.mutate([selectedRow.id]);
  };

  const handleConfirmDelete = (row) => {
    setSelectedRow(row);
    toggleDelete();
  };

  let content = null;

  if (result.isLoading) {
    content = (
      <CustomSpinner messageKey="shared-documents.loading" />
    );
  } else if (result.isError) {
    content = (
      <FormattedMessage id="shared-documents.loading.error" />
    );
  } else if (page?.list?.length === 0) {
    content = (
      <FormattedMessage id="shared-documents.msg.empty" />
    );
  } else {

    const pagination = <ListPagination page={currentPage} totalItems={page.total} pageSize={pageSize} onPageChange={onPageChange} showSinglePage={false} />;

    content = (
      <Fragment>
        <DataTable responsive bordered hover className='shared-documents-table' data={page.list} columns={dataTableColumns}
          actionsHeaderStyle={{width: "60px"}} renderRowActions={(row, rowIndex) => {
            return (
              <Fragment>
                <i className="fas fa-trash-alt" role="button" onClick={() => handleConfirmDelete(row)}></i>
              </Fragment>
            );
          }}/>
        {pagination}
      </Fragment>
    );

  }

  return (
    <div>
      <div className='d-flex justify-content-between'>
        <h2 className='section-title fs-5 flex-grow-1'><FormattedMessage id="shared-documents" /></h2>
      </div>
      <div className='section-content'>
        {content}
      </div>
      {openDeleteConfirm ? <DeleteModal open={openDeleteConfirm} toggle={toggleDelete} onConfirm={handleDelete} disabled={deleteMutation.isLoading} /> : null}
    </div>
  );
};

export default SharedDocumentList;

const DeleteModal = ({open, toggle, onConfirm, disabled = false}) => {

  return (
    <Modal isOpen={open} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>
        <FormattedMessage id="confirm" />
      </ModalHeader>
      <ModalBody>
        <WarningMessage messageKey="shared-documents.delete.warning" />
        <FormattedMessage id="shared-documents.delete" />
      </ModalBody>
      <ModalFooter>
        <div className='d-flex justify-content-end'>
          <Button color='danger' onClick={onConfirm} disabled={disabled}>
            <FormattedMessage id="shared-documents.buttons.delete" />
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};