
export const print_seasonality = 
	{
			1:		0.6427,
				2:		1.372,
				3:		1.5424,
				4:		1.8904,
				5:		1.2067,
				6:		1.0181,
				7:		0.6849,
				8:		0.5119,
				9:		1.0738,
				10:		1.1132,
				11:		1.0484,
				12:		1.1447,
				13:		0.7088,
				14:		0.5987,
				15:		0.5986,
				16:		0.7709,
				17:		0.5035,
				18:		0.5152,
				19:		0.6805,
				20:		0.6535,
				21:		0.5443,
				22:		0.4412,
				23:		0.3174,
				24:		0.5759,
				25:		0.6287,
				26:		0.435,
				27:		0.4125,
				28:		0.4931,
				29:		0.3537,
				30:		0.5121,
				31:		0.5497,
				32:		0.5586,
				33:		0.8757,
				34:		0.6479,
				35:		0.6013,
				36:		0.5735,
				37:		0.7978,
				38:		0.9272,
				39:		0.9679,
				40:		1.1332,
				41:		0.9601,
				42:		0.7967,
				43:		1.1073,
				44:		1.2617,
				45:		1.6115,
				46:		1.6398,
				47:		0.991,
				48:		1.589,
				49:		1.0984,
				50:		1.0763,
				51:		1.4605,
				52:		1.5979,
				53:		1.643,
				54:		1.1546,
				55:		0.8783,
				56:		0.8029,
				57:		0.7871,
				58:		1.0491,
				59:		0.826,
				60:		1.1082,
				61:		0.9396,
				62:		0.6743,
				63:		0.8072,
				64:		0.6333,
				65:		1.0936,
				66:		0.9516,
				67:		1.1309,
				68:		1.1018,
				69:		1.0944,
				70:		0.4876,
				71:		0.6012,
				72:		0.722,
				73:		0.9784,
				74:		0.9539,
				75:		0.7669,
				76:		0.7892,
				77:		0.7664,
				78:		0.4298,
				79:		0.7848,
				80:		1.1101,
				81:		0.9373,
				82:		0.8146,
				83:		0.6857,
				84:		0.5578,
				85:		0.6513,
				86:		0.984,
				87:		1.0621,
				88:		0.92,
				89:		0.912,
				90:		0.8041,
				91:		0.8357,
				92:		0.3652,
				93:		0.8435,
				94:		0.7533,
				95:		0.7819,
				96:		0.7926,
				97:		0.67,
				98:		0.3567,
				99:		0.3802,
				100:		0.5579,
				101:		0.4975,
				102:		0.8276,
				103:		0.6613,
				104:		0.6766,
				105:		0.3891,
				106:		0.7927,
				107:		0.7287,
				108:		0.759,
				109:		0.8548,
				110:		0.7842,
				111:		0.6381,
				112:		0.5761,
				113:		0.6171,
				114:		1.0217,
				115:		0.9532,
				116:		0.9551,
				117:		0.8786,
				118:		0.8676,
				119:		0.8152,
				120:		0.5769,
				121:		0.6045,
				122:		0.9102,
				123:		1.0612,
				124:		1.3288,
				125:		1.4636,
				126:		1.3946,
				127:		1.0662,
				128:		1.6524,
				129:		2.4313,
				130:		2.0244,
				131:		1.8132,
				132:		1.6323,
				133:		1.4692,
				134:		1.1283,
				135:		1.4921,
				136:		1.7593,
				137:		1.8841,
				138:		1.7106,
				139:		1.9566,
				140:		1.469,
				141:		1.4569,
				142:		1.7225,
				143:		1.8945,
				144:		2.039,
				145:		2.1253,
				146:		1.6237,
				147:		1.181,
				148:		0.5224,
				149:		0.7865,
				150:		1.7409,
				151:		1.3921,
				152:		1.6095,
				153:		1.2545,
				154:		1.0851,
				155:		0.7374,
				156:		0.97,
				157:		0.9227,
				158:		0.9566,
				159:		0.9506,
				160:		0.8635,
				161:		0.7141,
				162:		0.4914,
				163:		0.8697,
				164:		0.7113,
				165:		0.9087,
				166:		0.7443,
				167:		0.802,
				168:		0.522,
				169:		0.3335,
				170:		0.8321,
				171:		0.6574,
				172:		0.3744,
				173:		0.4023,
				174:		0.4087,
				175:		0.3275,
				176:		0.1906,
				177:		0.2303,
				178:		0.2772,
				179:		0.4441,
				180:		0.2877,
				181:		0.2368,
				182:		0.1452,
				183:		0.2207,
				184:		0.2345,
				185:		0.1861,
				186:		0.1971,
				187:		0.2931,
				188:		0.5018,
				189:		0.1614,
				190:		0.1822,
				191:		0.1911,
				192:		0.2572,
				193:		0.2545,
				194:		0.237,
				195:		0.1686,
				196:		0.0642,
				197:		0.2412,
				198:		0.1391,
				199:		0.1828,
				200:		0.2166,
				201:		0.1687,
				202:		0.1694,
				203:		0.2138,
				204:		0.2307,
				205:		0.1151,
				206:		0.0494,
				207:		0.079,
				208:		0.0755,
				209:		0.0401,
				210:		0.0654,
				211:		0.0906,
				212:		0.0547,
				213:		0.3863,
				214:		0.2295,
				215:		0.3172,
				216:		0.1605,
				217:		0.1633,
				218:		0.1237,
				219:		0.2145,
				220:		0.1496,
				221:		0.2546,
				222:		0.1335,
				223:		0.1471,
				224:		0.069,
				225:		0.1304,
				226:		0.1439,
				227:		0.1478,
				228:		0.3548,
				229:		0.3248,
				230:		0.352,
				231:		0.1493,
				232:		0.1432,
				233:		0.3739,
				234:		0.3004,
				235:		0.1688,
				236:		0.1523,
				237:		0.1809,
				238:		0.1535,
				239:		0.164,
				240:		0.1445,
				241:		0.1025,
				242:		0.1154,
				243:		0.3149,
				244:		0.1415,
				245:		0.2078,
				246:		0.1496,
				247:		0.2226,
				248:		0.2363,
				249:		0.2937,
				250:		0.4241,
				251:		0.1872,
				252:		0.1699,
				253:		0.2219,
				254:		0.2494,
				255:		0.2311,
				256:		0.3389,
				257:		0.4243,
				258:		0.4613,
				259:		0.2586,
				260:		0.491,
				261:		0.7276,
				262:		1.2004,
				263:		1.2624,
				264:		1.6787,
				265:		0.8685,
				266:		1.2323,
				267:		0.8936,
				268:		1.4112,
				269:		1.8916,
				270:		2.1703,
				271:		2.079,
				272:		1.6282,
				273:		1.426,
				274:		2.0573,
				275:		2.6899,
				276:		2.8714,
				277:		2.919,
				278:		2.3527,
				279:		1.9764,
				280:		1.6182,
				281:		1.4065,
				282:		2.192,
				283:		2.5808,
				284:		2.0159,
				285:		2.1669,
				286:		2.2692,
				287:		0.9856,
				288:		1.4661,
				289:		1.8774,
				290:		1.9917,
				291:		1.8624,
				292:		1.9291,
				293:		1.7957,
				294:		1.6857,
				295:		1.2605,
				296:		2.0874,
				297:		1.8416,
				298:		0.7092,
				299:		1.4252,
				300:		1.7022,
				301:		1.0104,
				302:		0.4386,
				303:		1.481,
				304:		1.3948,
				305:		1.7004,
				306:		1.9596,
				307:		1.5851,
				308:		1.449,
				309:		0.9965,
				310:		1.8171,
				311:		1.9681,
				312:		1.9865,
				313:		1.9212,
				314:		1.1072,
				315:		1.0819,
				316:		0.6942,
				317:		1.4109,
				318:		2.1723,
				319:		1.9395,
				320:		1.6644,
				321:		1.5132,
				322:		0.9834,
				323:		0.9908,
				324:		1.3721,
				325:		2.3322,
				326:		1.757,
				327:		1.6056,
				328:		1.3326,
				329:		0.9137,
				330:		1.0043,
				331:		1.1474,
				332:		1.4607,
				333:		1.7798,
				334:		1.6499,
				335:		1.3547,
				336:		1.1577,
				337:		1.2545,
				338:		1.3503,
				339:		1.6951,
				340:		1.8559,
				341:		2.2048,
				342:		1.8931,
				343:		1.8196,
				344:		1.7186,
				345:		1.7752,
				346:		2.0446,
				347:		2.6953,
				348:		2.4743,
				349:		2.372,
				350:		1.9064,
				351:		1.6386,
				352:		2.362,
				353:		2.9763,
				354:		3.5712,
				355:		3.8717,
				356:		2.7466,
				357:		2.0958,
				358:		1.0382,
				359:		1.1463,
				360:		2.0,
				361:		2.4283,
				362:		1.9846,
				363:		2.1106,
				364:		1.4602,
				365:		0.6111,
	}

