import React from "react"
import { Button, Col, Container, Row } from "reactstrap";
import { Page } from "../../commons/components/Page";
import { Link, matchPath, useLocation, useMatches, useNavigate, useOutlet } from "react-router-dom";

import './ReportingPage.css';
import ReportsList from "../components/ReportsList";
import { FormattedMessage } from "react-intl";

const ReportingPage = (props) => {

  const outlet = useOutlet();
  const navigate = useNavigate();
  const { search } = useLocation();

  const onAddReport = () => {
    // Preserve query params to hide menu entry
    navigate("/reporting/evaluation" + search);
  }

  return (
    <Page>
      <Container fluid>
        <Row>
          <Col>
          
          <div className='d-flex justify-content-between'>
            <div className="section-title fs-5 flex-grow-1"><Breadcrumbs /></div>
            {outlet ? null : <Button className='ms-2' size='sm' onClick={onAddReport}>
              <i className='fas fa-plus me-0 me-md-2' />
              <span className='d-none d-md-inline'>
                <FormattedMessage id="reports.buttons.add.newEvaluationReport" />
              </span>
            </Button> }
          </div>
          </Col>
        </Row>
        <Row className="mb-3 mt-3">
          {outlet ? outlet : <ReportsList />}
        </Row>
      </Container>
    </Page>
  );
};

export default ReportingPage;

const Breadcrumbs = () => {

  let location = useLocation();
  let matches = useMatches();

  let crumbs = matches
    .filter((match) => Boolean(match.handle?.crumb))
    .map((match) => match.handle.crumb(match.data));

  return (
    <ul className="zc-reporting-breadcrumbs">
      {crumbs.map((crumb, index) => {

        if (!!matchPath(location.pathname, crumb.path)) {
          return crumb.title
        }

        return <li key={index}><Link to={crumb.path}>{crumb.title}</Link></li>
      })}
    </ul>
  );
}