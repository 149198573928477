import React from "react"
import { Container } from "reactstrap";
import { Page } from "../../commons/components/Page";
import { useParams } from "react-router-dom";
import AdForm from "../components/AdForm";

const AdPage = () => {

  const { campaignId, adId } = useParams();

  return (
    <Page>
      <Container fluid>
        <AdForm campaignId={campaignId} adId={adId}/>
      </Container>
    </Page>
  );
};

export default AdPage;
