import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { Form, FormGroup, Input, Label } from "reactstrap";
import InputErrors from "../../commons/components/InputErrors";

const DeliveryConfigRangeForm = ({id, onSave, defaultValues, disabled = false}) => {

  const { register, handleSubmit, setError, setFocus, formState: { errors } } = useForm({
    mode: "onSubmit",
    defaultValues: defaultValues
  });

  useEffect(() => {
    setFocus("name");
  }, [setFocus]);

  const { ref: refMin, ...registerMin } = register('minimum', { required: true });
  const { ref: refMax, ...registerMax } = register('maximum', { required: true });
  const { ref: refPrice, ...registerPrice } = register('price', { required: true });
  const { ref: refDays, ...registerDays } = register('days', { required: true });

  const onSubmit = (data) => {
    onSave(data, setError);
  };

  return (
    <Form id={id} onSubmit={handleSubmit(onSubmit)}>
      <FormGroup>
        <Label for='minimum'>
          <FormattedMessage id="delivery-config.range.fields.min" />
        </Label>
        <Input id="minimum"  name="minimum" type="number" innerRef={refMin} {...registerMin} disabled={disabled} />
        <InputErrors fieldName="minimum" errors={errors} />
      </FormGroup>
      <FormGroup>
        <Label for='maximum'>
          <FormattedMessage id="delivery-config.range.fields.max" />
        </Label>
        <Input id="maximum"  name="maximum" type="number" innerRef={refMax} {...registerMax} disabled={disabled} />
        <InputErrors fieldName="maximum" errors={errors} />
      </FormGroup>
      <FormGroup>
        <Label for='price'>
          <FormattedMessage id="delivery-config.range.fields.price" />
        </Label>
        <Input id="price"  name="price" type="number" step="any" innerRef={refPrice} {...registerPrice} disabled={disabled} />
        <InputErrors fieldName="price" errors={errors} />
      </FormGroup>
      <FormGroup>
        <Label for='days'>
          <FormattedMessage id="delivery-config.range.fields.days" />
        </Label>
        <Input id="days"  name="days" type="number" innerRef={refDays} {...registerDays} disabled={disabled} />
        <InputErrors fieldName="days" errors={errors} />
      </FormGroup>
    </Form>
  );
};

export default DeliveryConfigRangeForm;