import { Col, Container, Row } from "reactstrap";
import { Page } from "../../commons/components/Page";
import SharedDocumentList from "../components/SharedDocumentList";

const SharedDocumentListPage = () => {

  return (
    <Page>
      <Container fluid>
        <Row>
          <Col>
            <SharedDocumentList />
          </Col>
        </Row>
      </Container>
    </Page>
  );
};

export default SharedDocumentListPage;