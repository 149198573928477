import React, {useState, useEffect} from "react";
import AlgoService from "../services/AlgoService";
import SimulateAlgo from "../services/SimulateAlgo";
import { useIntl } from "react-intl";
import CampaignList from "./CampaignList";
import CampaignGraph from "./CampaignGraph";
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';
import { useMutation, useQuery } from "@tanstack/react-query";
import InputErrors from "../../commons/components/InputErrors";
import moment from "moment";
import { Form, Button, FormGroup, Input, Label, Row, InputGroup, Modal, ModalBody, ModalFooter, ModalHeader, ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle, ButtonGroup } from "reactstrap";
import './AlgoForm.css'
import { handleErrors } from "../../commons/functions";
import ToolTipItem from "../../commons/components/shared/ToolTipItem";

const defaultConfig = {
  days: 90,
  avgViewsWeb: 0,
  avgViewsPrint: 0,
  margin: 0,
  boost30: 0,
  boost60: 0,
  boost90: 0,
  smallReachMulti: 0,
  startDate: moment(),
  endDate: moment().add(90, 'days'),
}

const emptyFilters = {
  names: [],
  type: "",
  lang: "",
}

const AlgoForm = (  ) => {
  const intl = useIntl();
  const [config, setConfig] = useState(defaultConfig);
  const [filters, setFilters] = useState(emptyFilters)
  const [filtered, setFiltered] = useState();
  const [lastEdited, setLastEdited] = useState();
  const [campaigns, setCampaigns] = useState();
  const [simulationData, setSimulationData] = useState();
  const [isTypeOpen, setIsTypeOpen] = useState(false);
  const [isLangOpen, setIsLangOpen] = useState(false);
  const [showGraph, setShowGraph] = useState(true);
  const types = ["PRINT", "WEB"];
  const [langs, setLangs] = useState([]);
  const [activeBtn, setActiveBtn] = useState(90);
  const [editedCampaigns, setEditedCampaigns] = useState([]);
  const [openConfirm, setOpenConfirm] = useState(false);

  const { register, handleSubmit, setValue, formState: { errors } } = useForm({
    mode: "onSubmit",
  });

  const algoData = useQuery([], () => AlgoService.getAlgoData(), {
    onSuccess: (response) => { 
      response.data.campaigns.forEach((camp) => { 
        camp.startDate = moment(camp.startDate, "DD-MM-YYYY").format("YYYY/MM/DD") 
        camp.endDate = moment(camp.endDate, "DD-MM-YYYY").format("YYYY/MM/DD") 
        if (!camp.dateCompeted) {
          camp.dateCompleted = "N/A"
        }
      })
      setCampaigns(response.data.campaigns)
      setLangs(Array.from(new Set(response.data.campaigns.map(camp => camp.language).filter(lang => lang !== null))))
      const algoConfig = response.data.config.configs
      setConfig({...config, 
        boost30 : algoConfig.ALGO_30_BOOST.value,
        boost60 : algoConfig.ALGO_60_BOOST.value,
        boost90 : algoConfig.ALGO_90_BOOST.value,
        smallReachMulti : algoConfig.ALGO_REACH_MULTI.value,
        avgViewsWeb : algoConfig.ALGO_AVG_WEB_AD_VIEWS.value,
        avgViewsPrint : algoConfig.ALGO_AVG_PRINT_AD_VIEWS.value,
      })
    } 
  });

  const updateMutation = useMutation(([data, setError]) => AlgoService.updateAlgoData(data, editedCampaigns), {
    onSuccess: (response, vars, context) => {
      toast.success(intl.formatMessage({id: "algo.msg.success.update"}));
      handleRefresh();
    },
    onError: (error, [data, setError], context) => {
      handleErrors(error, "algo.msg.error.update", setError, toast, intl);
    }
  });

  const { ref: refMargin, ...registerMargin } = register('margin');
  const { ref: refBoost30, ...registerBoost30 } = register('boost30');
  const { ref: refBoost60, ...registerBoost60 } = register('boost60');
  const { ref: refBoost90, ...registerBoost90 } = register('boost90');
  const { ref: refSmallReachMulti, ...registerSmallReachMulti } = register('smallReachMulti');
  const { ref: refStartDate, ...registerStartDate } = register('startDate');
  const { ref: refEndDate, ...registerEndDate } = register('endDate');
  const { ref: refCampaigns, ...registerCampaigns } = register('campaigns');

  const onSubmit = (data, setError) => {
      updateMutation.mutate([data, setError]);
      toggleConfirm()
  }

   useEffect(() => {
    if (campaigns) {
      campaigns.forEach(camp => {
        camp.complete = Math.round(camp.totalViews / camp.maxViews * 10000) / 100 
        camp.reachBoost = Math.round(config.smallReachMulti * (100 - camp.reach) * 100) / 100
        camp.totalBoost = camp.boost + camp.reachBoost
      })      
      const simulatedData = SimulateAlgo(campaigns, config);
      setSimulationData(simulatedData);
    }
  }, [campaigns, config]);

  useEffect(() => {
    if (config) {
      setValue('days', config.days)
      setValue('margin', config.margin)
      setValue('boost30', config.boost30)
      setValue('boost60', config.boost60)
      setValue('boost90', config.boost90)
      setValue('smallReachMulti', config.smallReachMulti)
      setValue('startDate', config.startDate.format("YYYY-MM-DD"))
      setValue('endDate', config.endDate.format("YYYY-MM-DD"))
    }
  }, [config])

  useEffect(() => {
    if (campaigns) {
      const filteredIds = new Set()
      for (const camp of campaigns) { 
        if (filters.type !== "" && camp.type !== filters.type) {
          continue
        }
        if (filters.names.length !== 0) {
          let matches = false
          for (const name of filters.names) {
            if (camp.name.toUpperCase().includes(name.toUpperCase())) {
              matches = true
              break
            }
          }
          if (!matches) {
            continue
          }
        }
        if (filters.lang !== "" && camp.language !== filters.lang) {
          continue
        }
        filteredIds.add(camp.id)
      }
      setFiltered(filteredIds)
    }
  }, [filters])


  
  const handleReachMultiChange = (event) => {
    setConfig({...config, smallReachMulti : event.target.value })
  }

  const handleBoostChange = (event, identifier) => {
    const val = event.target.value;
    switch (identifier) {
      case 30:
        setConfig( {...config, boost30 : val })
        break;
      case 60:
        setConfig( {...config, boost60 : val })
        break;
      case 90:
        setConfig( {...config, boost90 : val })
        break;
      default:
        break;
    }
  }

  const updateCampaigns = (...camps) => {
    const temp = [...campaigns]
    for (const camp of camps) {
      const tempIndex = temp.findIndex((item) => {
          return item.id === camp.id 
      });
      temp[tempIndex] = camp 
      const editedIndex = editedCampaigns.findIndex((item) => {
        return item.id === camp.id
      })
      if (editedIndex !== -1) {
        editedCampaigns[editedIndex] = camp
      }
      else {
        editedCampaigns.push(camp)
      }
    }
    setCampaigns(temp)
  }

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault()
    }
  }

  const handleNameFilter = (e) => {
    const val = e.target.value
    if (val.length !== 0) {
      setFilters({...filters, names :e.target.value.split(",").map(str => str.trim()).filter(str => str.length > 0)})
    }
    else {
      setFilters({...filters, names : []})
    }
  }

  const handleTypeFilter = (type) => {
    setFilters({...filters, type: type})
  }

  const handleLangFilter = (lang) => {
    setFilters({...filters, lang: lang})
  }

  const handleStartDateFilter = (value) => {
    const start = moment(value)
    const today = moment()
    if (start && start.isSameOrAfter(today)) {
      const range = config.days + Math.ceil(moment.duration(start.diff(today)).asDays());
      setConfig( {...config, startDate : start, days : range })
    }
  }

  const handleEndDateFilter = (value) => {
    const end = moment(value)
    if (end) {
      
      const today = moment();
      const range = Math.ceil(moment.duration(end.diff(today)).asDays());
      if (range > config.days) {
        setConfig( {...config, endDate: end, days : Math.ceil(moment.duration(end.diff(today)).asDays())})
      }
      else {
        setConfig( {...config, endDate: end, days : 365 })
      }
    }
  }

  const changeDateRange = (days) => {
    setActiveBtn(days)
    const today = moment()
    handleStartDateFilter(today.format("YYYY-MM-DD"))
    handleEndDateFilter(today.add(days, 'days').format("YYYY-MM-DD"))
  }

  const handleRefresh = () => {
    setConfig(defaultConfig)
    setActiveBtn(90)
    algoData.refetch()
    setEditedCampaigns([])
    setLastEdited(null)
  }

  const clearFilter = () => {
    setFilters(emptyFilters)
    setFiltered(null);
  }

  const graph = <CampaignGraph simulationData={simulationData} filtered={filtered} lastEdited={lastEdited} startDate={config.startDate} endDate={config.endDate} />

  const table = <CampaignList campaigns={campaigns} updateCampaigns={updateCampaigns} filtered={filtered} selected={lastEdited?.id} setSelected={setLastEdited}/>

  const handleShowGraph = (bool) => {
    setShowGraph(bool)
  }



  const toggleConfirm = () => {
    setOpenConfirm(!openConfirm);
  };

  const timeRangeBtnLbls = [
    {"label": "30D", value: 30},
    {"label": "3M", value: 90},
    {"label": "6M", value: 180},
    {"label": "1Y", value: 365},
  ];

  if (!simulationData) {
    return (
      <div>loading...</div>
    )
  }
  return (
    <React.Fragment>
    <h2 className='section-title fs-5 px-1 mb-2 m-1'> Campaign Algorithm Simulation:</h2>
      <Row>
        <div className="col-lg-1 col formSection">
          <div id="selectIcons" className="d-flex justify-content-around align-items-center">
            <div className="iconContainer">
              <i id="graphIcon" className="fas fa-chart-line fa-2x" onClick={() => handleShowGraph(true)} />
              <ToolTipItem id={"graphIcon"} content={"Show the graph"} placement={"bottom"} />
            </div>
            <div className="iconContainer">
              <i id="tableIcon" className="fas fa-table fa-2x" onClick={() => handleShowGraph(false)} />
              <ToolTipItem id={"tableIcon"} content={"Show the table"} placement={"bottom"} />
            </div>
          </div>
        </div>
        <Form className="formSection col-lg-10 col d-flex flex-row p-1 justify-content-around">
          <InputGroup className="p-1">
            <ButtonDropdown toggle={() => setIsTypeOpen(!isTypeOpen)} isOpen={isTypeOpen}>
              <DropdownToggle caret>
                {filters.type !== "" ? filters.type : "Campaign Type"}
              </DropdownToggle>
              <DropdownMenu>
                {types.map(type => 
                   <DropdownItem key={type} onClick={() => handleTypeFilter(type)}>
                    {type}
                  </DropdownItem>
                )}
              </DropdownMenu>
            </ButtonDropdown>
              <Input id="nameFilter" placeholder="Name:" onChange={val => handleNameFilter(val)} onKeyDown={e => handleKeyDown(e)}></Input>
            <ToolTipItem id={"nameFilter"} content={"You can filter by multiple terms seperated by a space"} placement={"top"} />
            <ButtonDropdown toggle={() => setIsLangOpen(!isLangOpen)} isOpen={isLangOpen}>
              <DropdownToggle caret>
                {filters.lang !== "" ? filters.lang : "Language"}
              </DropdownToggle>
              <DropdownMenu>
                {langs.map(lang => 
                   <DropdownItem key={lang} onClick={() => handleLangFilter(lang)}>
                    {lang}
                  </DropdownItem>
                )}
              </DropdownMenu>
            </ButtonDropdown>
            <Button id={"clearFilter"} type="reset" onClick={() => clearFilter()}>x</Button>
            <ToolTipItem id={"clearFilter"} content={"Clear all filters"} placement={"top"} />
          </InputGroup>
        </Form>
      </Row>
      
      <Row>
        <div className="col-lg-2 col-12">
          <Form className="mr-2 my-2 ml-0" >
            <div className="formSection" style={{marginLeft: 0}}>

              <Row className="mb-1">
                <ButtonGroup>
                  {timeRangeBtnLbls.map((item, index) => {
                    return (
                      <Button key={index} className={activeBtn === item.value ? "active" : ""} 
                        onClick={() => changeDateRange(item.value)} outline>
                        {item.label}
                      </Button>
                    );
                  })}
                </ButtonGroup>
              </Row>
              <Row>
                <FormGroup id="fromGroup">
                  <Label className="text-muted" for='StartDate'>
                    From:
                  </Label>
                  <Input type="date" id="startDate" min={moment().format("YYYY-MM-DD")} innerRef={refStartDate} {...registerStartDate} onChange={e => handleStartDateFilter(e.target.value)} onKeyDown={e => handleKeyDown(e)} onReset={e => handleStartDateFilter(e.target.value)}  ></Input>
                  <ToolTipItem id={"startDate"} content={"First date shown on the graph"} placement={"right"} />
                </FormGroup>
              </Row>
              <Row>
                <FormGroup id="untilGroup">
                  <Label className="text-muted" for='StartDate'>
                    Until:
                  </Label>
                  <Input type="date" id="endDate" innerRef={refEndDate} {...registerEndDate} onChange={e => handleEndDateFilter(e.target.value)} onKeyDown={e => handleKeyDown(e)} onReset={e => handleEndDateFilter(e.target.value)}  ></Input>
                  <ToolTipItem id={"endDate"} content={"Last date shown on the graph"} placement={"right"} />
                </FormGroup>
              </Row>
            </div>
            <div className="formSection" style={{marginLeft: 0}}>
              <p className="mb-1" align="center"><b>Parameters:</b></p>
              <Row>
                <FormGroup id="boost30Group">
                  <Label className="text-muted" for='boost30'>
                    30 day boost (%):
                  </Label>
                  <Input id='boost30' min={0} innerRef={refBoost30} {...registerBoost30} type='number' onChange={val => handleBoostChange(val, 30)} onKeyDown={e => handleKeyDown(e)}/>
                  <InputErrors fieldName="boost30" errors={errors} />
                </FormGroup>
                <ToolTipItem id={"boost30Group"} content={"The % boost all camapgins with 30 days or less remainging receive"} />
              </Row>
              <Row>
                <FormGroup id="boost60Group">
                  <Label className="text-muted" for='boost60'>
                    60 day boost (%):
                  </Label>
                  <Input id='boost60' min={0} innerRef={refBoost60} {...registerBoost60} type='number' onChange={val => handleBoostChange(val, 60)} onKeyDown={e => handleKeyDown(e)}/>
                  <InputErrors fieldName="boost60" errors={errors} />
                </FormGroup>
                <ToolTipItem id={"boost60Group"} content={"The % boost all camapgins with 60 days or less remainging receive"} />
              </Row>
              <Row>
                <FormGroup id="boost90Group">
                  <Label className="text-muted" for='boost90'>
                    90 day boost (%):
                  </Label>
                  <Input id='boost90' min={0} innerRef={refBoost90} {...registerBoost90} type='number' onChange={val => handleBoostChange(val, 90)} onKeyDown={e => handleKeyDown(e)}/>
                  <InputErrors fieldName="boost90" errors={errors} />
                </FormGroup>
                <ToolTipItem id={"boost90Group"} content={"The % boost all camapgins with 90 days or less remainging receive"} />
              </Row>
              <Row>
                <FormGroup id="reachMultiGroup">
                  <Label className="text-muted" for='reachMulti'>
                    Reach Multiplier:
                  </Label>
                  <Input id='smallReachMulti' min={-5} max={5} innerRef={refSmallReachMulti} {...registerSmallReachMulti}  type='number' onChange={val => handleReachMultiChange(val)} onKeyDown={e => handleKeyDown(e)}/>
                  <InputErrors fieldName="smallReachMulti" errors={errors} />
                </FormGroup>
                <ToolTipItem id={"reachMultiGroup"} content={"The multiplier that is applied to the % boost non-global campaign receive (0 means no extra boost for non-global campaigns)"} />
              </Row>
            </div>
            <Row className="py-1">
              <div className="d-flex justify-content-around">
                <Button type="button" className="btn btn-primary" onClick={() => toggleConfirm()}>Save Changes</Button>
                { openConfirm ? <ConfimModal open={openConfirm} toggle={toggleConfirm} handleSubmit={handleSubmit(onSubmit)} config={config} editedCampaigns={editedCampaigns} disabled={updateMutation.isLoading} /> : null}
                <Button type="button" outline onClick={() => handleRefresh()}>Refresh</Button>
              </div>
            </Row>
          </Form>
        </div>
        <div className="col">
          <div className={"d-flex flex-column justify-content-between"} style={{overflow: "auto", maxWidth: "100%"}}>
            {showGraph ? graph : table}
          </div>
        </div>
      </Row>
    </React.Fragment>
  );
};

export default AlgoForm;

const ConfimModal = ({open, toggle, config, editedCampaigns, handleSubmit, disabled = false}) => {

  return (
    <Modal isOpen={open} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>
        Confirm Algorithm Update
      </ModalHeader>
      <ModalBody>
        Are you sure you want to update the following parameters?
        <ul>
          <li><b>30 day boost:</b> {config.boost30}%</li>
          <li><b>60 day boost:</b> {config.boost60}%</li>
          <li><b>90 day boost:</b> {config.boost90}%</li>
          <li><b>Small Reach Multiplier:</b> {config.smallReachMultiplier}</li>
        </ul>
        {editedCampaigns.size !== 0 ?
        <div>The following campaigns will also be updated:
            <ul>
          {editedCampaigns.map(camp => {
            return <li><b>{camp.name}</b>
                 <br/><b>Boost:</b> {camp.boost}% 
                 <br/><b>Max Views:</b> {camp.maxViews} 
                 <br/><b>End Date:</b> {camp.endDate} 
                </li>
            })
          }
            </ul>
        </div>
        : ""}

      </ModalBody>
      <ModalFooter>
        <div className='d-flex'>
          <Button color='primary' className="mx-1" type="button" onClick={handleSubmit} disabled={disabled}>
            Confirm
          </Button>
          <Button color='danger' className="mx-1" type="button" onClick={toggle}>
            Cancel
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};
