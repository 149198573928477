import api from "../../Api";
import { buildApiHeaders } from "../../commons/functions";

class CampaignService {

  static async list(page, pageSize, filter) {

    if (filter) {
      filter["page"] = page;
      filter["pageSize"] = pageSize;
    } else {
      filter = {
        "page": page,
        "pagesize": pageSize
      }
    }

    const headers = buildApiHeaders();
    return api.get("/v1/admin/campaigns", {params: filter, headers: headers});
  }

  static async sendgrid() {
    const headers = buildApiHeaders();
    return api.get("/v1/admin/campaigns/sendgrid", {headers: headers});
  }

}

export default CampaignService;