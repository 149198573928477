import api from "../../Api";
import { buildApiHeaders } from "../../commons/functions";

class AlgoService {

  static async getAlgoData() {
    const headers = buildApiHeaders();
    return api.get('/v1/algo/data', {headers: headers});
  }

  static async updateAlgoData(data, editedCampaigns) {
    data["updatedCampaigns"] = editedCampaigns
    const headers = buildApiHeaders();
    return api.post('/v1/algo/data', data, {headers: headers});
  }
}

export default AlgoService;
