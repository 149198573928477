import api from "../../Api";
import { buildApiHeaders } from "../../commons/functions";

class UserService {

  static async getStats(identifier) {
    const headers = buildApiHeaders();
    return api.get(`/v1/admin/users/${identifier}/stats`, {headers: headers});
  }
}

export default UserService;