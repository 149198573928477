import React, { Fragment } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useMatch } from "react-router-dom";
import { Nav, NavItem, NavLink } from "reactstrap";
//import LoginService from "../../login/services/LoginService";
import { useAuth } from "../hooks/useAuth";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { LogoWhite } from "./icons/LogoWhite";
import { LogoWhiteSm } from "./icons/LogoWhiteSm";
import { LanguageSelector } from "./LanguageSelector";

import './MainMenu.css';

export const MainMenu = (props) => {

  const [collapsed, setCollapsed] = useLocalStorage("mainMenuCollapsed", false);
  const { auth, logout } = useAuth();

  const handleCollapse = (e) => {
    e.preventDefault();
    setCollapsed(!collapsed);
  }

  const handleLogout = (e) => {
    e.preventDefault();

    // LoginService.logout(auth.token).then(data => {
    //   logout();
    // }).catch(error => {
    //   console.log(error);
    // });
  }

  const logoSm = (
    <LogoWhiteSm height="30px"/>
  );

  return (
    <div className='d-flex flex-column flex-shrink-0 p-2 bg-zc'>
    <div role="button" className={`text-decoration-none text-center${collapsed ? "": " text-md-start"}`} onClick={handleCollapse}>
      {collapsed ? logoSm : <Fragment><span className="d-none d-md-inline"><LogoWhite /></span><span className="d-inline d-md-none">{logoSm}</span></Fragment>}
    </div>
    <hr className="text-white"/>
    <Nav pills vertical className="mb-auto" >
      <MenuItem collapsed={collapsed} textKey="home" path="/" icon="fas fa-home" />
      <MenuItem collapsed={collapsed} textKey="creditPolicy" path="/credit-policy" icon="fas fa-credit-card" />
      <MenuItem collapsed={collapsed} textKey="campaigns" path="/campaigns" icon="fas fa-list-alt" />
      <MenuItem collapsed={collapsed} textKey="profile" path="/profile" icon="fas fa-magic" />
      <MenuItem collapsed={collapsed} textKey="users" path="/users" icon="fas fa-users" />
      <MenuItem collapsed={collapsed} textKey="profileRequest" path="/profile/request" icon="far fa-address-book" />
      <MenuItem collapsed={collapsed} textKey="documents" path="/documents" icon="fas fa-book" />
      <MenuItem collapsed={collapsed} textKey="configuration" path="/configuration" icon="fas fa-cogs" />
      <MenuItem collapsed={collapsed} textKey="deliveries" path="/deliveries" icon="fas fa-truck" />
      <MenuItem collapsed={collapsed} textKey="delivery-config" path="/deliveries/config" icon={<DeliveryConfigIcon />} />
      <MenuItem collapsed={collapsed} textKey="deliveriesDiscounts" path="/deliveries/discounts" icon={<DeliveryDiscountIcon />} />
      <MenuItem collapsed={collapsed} textKey="promoCodes" path="/promo-codes" icon="fas fa-percent" />
    </Nav>
    <hr className="text-white"/>
    <a href="#logout" onClick={handleLogout} className={`text-decoration-none text-white px-3 py-2 text-center${collapsed ? "": " text-sm-start"}`}>
      <i className="fas fa-sign-out-alt"></i>
      {collapsed ? null:<span className="d-none d-md-inline ms-2"><FormattedMessage id="buttons.logout" /></span>}
    </a>
    <LanguageSelector collapsed={collapsed} />
  </div>
  );
}

const MenuItem = ({path, textKey, collapsed, icon}) => {
  const match = useMatch(path);
  const intl = useIntl();
  
  return (
    <NavItem>
      <NavLink href={path} className={`menu-item text-center${collapsed ? "": " text-sm-start"}${match !== null?"":" text-white"}`} active={match !== null} title={intl.formatMessage({id: textKey})}>
        {React.isValidElement(icon) ? icon : <i className={`${icon}`}/> }
        {collapsed? null: <span className="ms-2 menu-item-text"><FormattedMessage id={textKey} /></span> }
      </NavLink>
    </NavItem>
  );
};

const DeliveryDiscountIcon = () => {
  return (
    <span className="fa-stack fa-delivery-discount" >
      <i className="fa fa-truck fa-stack-1x"></i>
      <i className="fa fa-tags fa-inverse fa-stack-1x"></i>
    </span>
  );
}

const DeliveryConfigIcon = () => {
  return (
    <span className="fa-stack fa-delivery-config">
      <i className="fa fa-truck fa-stack-1x"></i>
      <i className="fa fa-cogs fa-inverse fa-stack-1x"></i>
    </span>
  );
}