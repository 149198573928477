import { useMutation, useQuery } from "@tanstack/react-query";
import React, { Fragment, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import ReportingService from "../services/ReportingService";
import CustomSpinner from "../../commons/components/Spinner";
import ListPagination from "../../commons/components/ListPagination";
import DataTable from "../../commons/components/DataTable";
import ReportsFilterForm from "./ReportsFilterForm";
import { toast } from 'react-toastify';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { handleApiErrors } from "../../commons/functions";

const dataTableColumns = [
  {
    headerKey: "reports.fields.name",
    selector: row => row.name
  },
  {
    headerKey: "reports.fields.type",
    selector: row => row.type,
    style: {
      "width": "250px"
    }
  },
  {
    headerKey: "reports.fields.status",
    selector: row => row.status,
    style: {
      "width": "150px"
    }
  },
  {
    headerKey: "reports.fields.created",
    selector: row => row.created,
    style: {
      "width": "200px"
    }
  },
  {
    headerKey: "reports.fields.updated",
    selector: row => row.updated,
    style: {
      "width": "200px"
    }
  }
  
];

const ReportsList = () => {

  const pageSize = 10;

  const [currentPage, setCurrentPage] = useState(0);
  const [page, setPage] = useState({total: 0, list: []});
  const [filter, setFilter] = useState({});
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const intl = useIntl();

  const result = useQuery({
    queryKey: ["reports-list", currentPage, pageSize, filter], 
    queryFn: () => ReportingService.list(currentPage, pageSize, filter),
    onSuccess: (response) => setPage({total: response.data.total, list: response.data.data })
  });

  const deleteMutation = useMutation((identifier) => ReportingService.delete(identifier), {
    onSuccess: (response, [identifier], context) => {
      toast.success(intl.formatMessage({id: "reports.list.msg.delete"}));
      result.refetch();
    },
    onError: (error, vars, context) => toast.error(handleApiErrors(intl, error, "reports.list.msg.error.delete")),
    onSettled: () => {
      setOpenDeleteConfirm(false);
    }
  });

  const onPageChange = (e, pageTotLoad) => {
    e.preventDefault();

    if (result.isLoading) {
      return;
    }

    setCurrentPage(pageTotLoad);
  }

  const onFilter = (filter, setError) => {
    setCurrentPage(0);
    setFilter(filter);
  }

  const onDownload = (row) => {
    ReportingService.generateDownloadUrl(row.id)
    .then(response => {

      window.open(response.data, "_blank");

    }).catch(error => {

    });
  }

  const toggleDelete = () => {
    if (!deleteMutation.isLoading) {
      setOpenDeleteConfirm(oldValue =>!oldValue);
    }
  };

  const handleDelete = () => {
    deleteMutation.mutate([selectedRow.id]);
  };

  const handleConfirmDelete = (row) => {
    setSelectedRow(row);
    toggleDelete();
  };


  let content = null;

  if (result.isLoading) {
    content = (
      <CustomSpinner messageKey="reports.list.loading" />
    );
  } else if (result.isError) {
    content = (
      <FormattedMessage id="reports.list.loading.error" />
    );
  } else {

    const filterContent = (
      <div className="mb-2">
        <ReportsFilterForm id="reportsListFilterForm" onFilter={onFilter} current={filter} disabled={result.isLoading} />
      </div>
    );

    if (page.total === 0) {

      content = (
        <Fragment>
          {filterContent}
          <FormattedMessage id="reports.list.empty" />
        </Fragment>
      );

    } else {

      const pagination = <ListPagination page={currentPage} totalItems={page.total} pageSize={pageSize} onPageChange={onPageChange} showSinglePage={false} />;

      content = (
        <Fragment>

          {filterContent}

          <DataTable responsive bordered hover className='reports-table' data={page.list} columns={dataTableColumns}
            actionsHeaderStyle={{width: "60px"}} renderRowActions={(row, rowIndex) => {
            
              return (
                <Fragment>
                  { row?.status === "DONE" ? <i className="fas fa-cloud-download-alt me-2" role="button" onClick={() => onDownload(row)}></i> : null }
                  <i className="fas fa-trash-alt" role="button" onClick={() => handleConfirmDelete(row)}></i>
                </Fragment>
              );

            }}/>
          {pagination}
        </Fragment>
      );
    }

  }

  return (
    <div>
    {content}
    { openDeleteConfirm && <DeleteModal open={true} toggle={toggleDelete} onConfirm={handleDelete} disabled={deleteMutation.isLoading}/> }
    </div>
  );

}

export default ReportsList;

const DeleteModal = ({open, toggle, onConfirm, disabled = false}) => {

  return (
    <Modal isOpen={open} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>
        <FormattedMessage id="confirm" />
      </ModalHeader>
      <ModalBody>
        <FormattedMessage id="reports.list.delete" />
      </ModalBody>
      <ModalFooter>
        <div className='d-flex justify-content-end'>
          <Button color='danger' onClick={onConfirm} disabled={disabled}>
            <FormattedMessage id="reports.buttons.delete" />
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};