import api from "../../Api";
import { buildApiHeaders } from "../../commons/functions";

class AdService {

  static async create(campaignId, data) {
    const headers = buildApiHeaders();
    headers['Content-Type'] = 'multipart/form-data';
    const formData = new FormData();
    formData.append('file', data.file);
    formData.append('type', data.type);
    formData.append('url', data.url);
    formData.append('premium', data.premium);
    return api.post(`/v1/admin/campaign/${campaignId}/ad`, data, {headers: headers});
  }

  static async update(campaignId, adId, data) {
    const headers = buildApiHeaders();
    headers['Content-Type'] = 'multipart/form-data';
    const formData = new FormData();
    if (data.file) {
      formData.append('file', data.file);
    }
    formData.append('type', data.type);
    formData.append('url', data.url);
    formData.append('active', data.active);
    formData.append('premium', data.premium);
    return api.put(`/v1/admin/campaign/${campaignId}/ad/${adId}`, data, {headers: headers});
  }

  static async delete(campaignId, adId) {
    const headers = buildApiHeaders()
    return api.delete(`/v1/admin/campaign/${campaignId}/ad/${adId}`, {headers: headers});
  }

  static async get(adId) {
    const headers = buildApiHeaders();
    return api.get(`/v1/admin/ad/${adId}`, {headers: headers});
  }
}

export default AdService;
