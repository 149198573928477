import api from "../../Api";
import { buildApiHeaders } from "../../commons/functions";

class DeliveryConfigService {

  static async list(page, pageSize) {
    const headers = buildApiHeaders();
    return api.get("/v1/admin/delivery-pricing-config", {params: {page: page, pageSize: pageSize}, headers: headers});
  }
  
  static async create(data) {
    const headers = buildApiHeaders();
    return api.put("/v1/admin/delivery-pricing-config", data, {headers: headers});
  }

  static async update(configId, data) {
    const headers = buildApiHeaders();
    return api.post(`/v1/admin/delivery-pricing-config/${configId}`, data, {headers: headers});
  }

  static async delete(configId) {
    const headers = buildApiHeaders();
    return api.delete(`/v1/admin/delivery-pricing-config/${configId}`, {headers: headers});
  }

  static async ranges(configId) {
    const headers = buildApiHeaders();
    return api.get(`/v1/admin/delivery-pricing-config/${configId}/ranges`, {headers: headers});
  }

  static async createRange(configId, data) {
    const headers = buildApiHeaders();
    return api.put(`/v1/admin/delivery-pricing-config/${configId}/ranges`, data, {headers: headers});
  }

  static async updateRange(configId, rangeId, data) {
    const headers = buildApiHeaders();
    return api.post(`/v1/admin/delivery-pricing-config/${configId}/ranges/${rangeId}`, data, {headers: headers});
  }

  static async deleteRange(configId, rangeId) {
    const headers = buildApiHeaders();
    return api.delete(`/v1/admin/delivery-pricing-config/${configId}/ranges/${rangeId}`, {headers: headers});
  }
}

export default DeliveryConfigService;